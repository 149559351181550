import { Comment, CommentBody, FileAttachment } from '@aminsights/contract';
import { USER_ASSETS_STORAGE_PATH } from '@aminsights/shared';
import { Button, Input, Modal, Upload } from 'antd';
import cx from 'classnames';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ReactComponent as PlusIcon } from '@/assets/svg/icons/icon-plus.svg';
import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import {
  useCreateComment,
  useUpdateComment,
} from '@/hooks/query-hooks/internal-hooks/useManageCommentary';
import FilesInfoToast from '@/partials/Files/FilesInfoToast';
import FilesUploadModal from '@/partials/Files/FilesUploadModal';
import { MAX_FILES_ALLOWED } from '@/partials/Files/utils';

const { TextArea } = Input;

interface ModalProps {
  isVisible: boolean;
  initialValue?: Comment;
  toggleModal: () => void;
}

const CommentaryFormModal: FC<ModalProps> = ({
  isVisible,
  initialValue,
  toggleModal,
}) => {
  const [isAttachmentsModalOpen, setIsAttachmentsModalOpen] = useState(false);
  const { data: fundState } = useFundByIsInParam();
  const { fundId, fundName } = fundState?.fund || {};
  const [isMaxAttachmentsToastVisible, setIsMaxAttachmentsToastVisible] =
    useState(false);
  const { state: featureSwitchState } = useFeatureSwitchContext();
  const { control, handleSubmit, reset, formState, watch, setValue } =
    useForm<CommentBody>({
      mode: 'all',
    });

  const attachments = watch('attachments') ?? [];

  const createComment = useCreateComment();
  const updateComment = useUpdateComment();

  useEffect(() => {
    if (initialValue) {
      reset({
        description: initialValue.description,
        attachments: initialValue.attachments ?? [],
      });
    } else {
      reset({ description: '', fundId, fundName, attachments: [] });
    }
  }, [fundName, fundId, initialValue, reset]);

  const handleSaveComment = handleSubmit(async data => {
    if (!fundId || !fundName) return;

    const payload = {
      description: data.description,
      fundId,
      fundName,
      attachments: attachments.map(attachment => ({
        originalFileName: attachment.originalFileName,
        storageFileKey: attachment.storageFileKey,
        fileSize: attachment.fileSize,
      })),
    };

    if (initialValue?._id) {
      await updateComment.mutateAsync({
        commentId: initialValue._id,
        body: payload,
      });
    } else {
      await createComment.mutateAsync({
        body: payload,
      });
    }

    toggleModal();
    reset({ description: '' });
  });

  const handleCloseModal = () => {
    if (formState.isSubmitting) return;

    toggleModal();
    reset({ description: '', fundName, attachments: [] });
  };

  return (
    <>
      <Modal
        centered={true}
        destroyOnClose
        open={isVisible && !isAttachmentsModalOpen}
        onCancel={handleCloseModal}
        title="Commentary"
        className={cx(
          'action-modal max-sm:full-page-modal',
          'md:min-w-[600px] lg:min-w-[800px] [&_.ant-modal-body]:px-10 [&_.ant-modal-body]:py-0',
          '[&_.ant-modal-body]:pt-4 [&_.ant-modal-close]:mr-4 [&_.ant-modal-footer]:pt-6',
        )}
        footer={[
          <Button
            key="cancel"
            size="large"
            type="link"
            onClick={handleCloseModal}
            disabled={formState.isSubmitting}
          >
            Cancel
          </Button>,
          <Button
            key="primary"
            size="large"
            type="primary"
            htmlType="submit"
            disabled={!formState.isValid || !formState.isDirty}
            onClick={handleSaveComment}
            loading={formState.isSubmitting}
          >
            {initialValue?._id ? 'Save' : 'Add'}
          </Button>,
        ]}
      >
        <div>
          <div className="flex flex-col gap-1">
            <label
              className="text-xs font-medium text-neutral-200"
              htmlFor="description"
            >
              Description
            </label>
            <Controller
              control={control}
              name="description"
              rules={{
                required: 'Description is required',
              }}
              render={({ field }) => (
                <TextArea
                  id="description"
                  className="!placeholder:text-sm !placeholder:text-neutral-100 !placeholder:font-normal !rounded border !border-[#BAC0D0] !border-solid h-[280px] !pb-0 text-sm !text-neutral-700 px-4 py-2 resize-none hover:!border-solid focus:!border-[#007FFF] focus:!border-2"
                  autoFocus={isVisible}
                  placeholder="Write something..."
                  disabled={createComment.isLoading || updateComment.isLoading}
                  {...field}
                />
              )}
            />
          </div>
          <div>
            {featureSwitchState.isUploadFilesEnabled && (
              <Button
                type="link"
                className="mt-1 p-0 text-sm text-primary hover:text-primary-400 gap-1"
                onClick={() => {
                  if (attachments.length >= MAX_FILES_ALLOWED)
                    setIsMaxAttachmentsToastVisible(true);
                  else setIsAttachmentsModalOpen(true);
                }}
              >
                <PlusIcon className="icon text-lg" />
                Add attachments
              </Button>
            )}
            {attachments.length > 0 && (
              <div
                className={`flex flex-col mt-10 
                  [&_.ant-upload-list]:mt-4 [&_.ant-upload-list-item]:h-10 [&_.ant-upload-list-item]:rounded 
                  [&_.ant-upload-list-item]:border-grey-50 [&_.ant-upload-list-item-thumbnail]:w-6 [&_.ant-upload-list-item-thumbnail]:h-auto 
                  [&_.ant-upload-list-item-image]:w-6 [&_.ant-upload-list-item-image]:h-auto [&_.ant-upload-list-item-name]:text-sm 
                  [&_.anticon-file]:w-4 [&_.anticon-file]:h-4 [&_.anticon-file]:align-[-2px] [&_.anticon-file>svg]:w-4 [&_.anticon-file>svg]:h-4
                  [&_.ant-upload-list-item-name]:text-primary [&_.ant-upload-list-item-action]:text-neutral-700`}
              >
                <p className="text-sm font-bold text-neutral-200">
                  Uploaded files summary
                </p>
                <Upload
                  listType="picture"
                  fileList={attachments.map(attachment => ({
                    uid: attachment.storageFileKey,
                    name: attachment.originalFileName,
                    size: attachment.fileSize,
                    url: attachment.fileUrl,
                    status: 'done',
                  }))}
                  onRemove={file => {
                    const newAttachments = attachments.filter(
                      attachment => attachment.storageFileKey !== file.uid,
                    );
                    setValue('attachments', newAttachments, {
                      shouldDirty: true,
                    });
                  }}
                  // Added empty function to prevent default preview behavior
                  onPreview={() => {}}
                  disabled={formState.isSubmitting}
                />
              </div>
            )}
          </div>
        </div>
      </Modal>
      <FilesUploadModal
        isVisible={isAttachmentsModalOpen}
        storagePath={USER_ASSETS_STORAGE_PATH.COMMENTS}
        toggleModal={() => setIsAttachmentsModalOpen(false)}
        currentNoOfFiles={attachments?.length}
        onUploadSuccess={(newAttachments: FileAttachment[]) => {
          setValue('attachments', [...attachments, ...newAttachments], {
            shouldDirty: true,
          });
        }}
      />
      <FilesInfoToast
        isVisible={isMaxAttachmentsToastVisible}
        onClose={() => {
          setIsMaxAttachmentsToastVisible(false);
        }}
      />
    </>
  );
};
export default CommentaryFormModal;
