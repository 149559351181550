import { AnnualisedReturn } from '@aminsights/contract';
import {
  EMPTY_DATA_POINT,
  MonthCode,
  isInvestmentTrust,
  roundOrEmpty,
} from '@aminsights/shared';

type RequiredFundProps = {
  legalStructure?: string;
  computedNavAnnualisedReturns?: AnnualisedReturn[];
  computedSharePriceAnnualisedReturns?: AnnualisedReturn[];
};

interface Props<T extends RequiredFundProps> {
  fund?: T;
  timePeriod: MonthCode;
  format?: (value: number | undefined) => string;
}

export const RenderAnnualisedReturnPct = <T extends RequiredFundProps>({
  fund,
  timePeriod,
  format,
}: Props<T>) => {
  if (!fund || !fund.legalStructure) {
    console.error('No fund or legal structure found to get computed return');
    return EMPTY_DATA_POINT;
  }

  const isTrust = isInvestmentTrust(fund.legalStructure);
  const computedReturns = isTrust
    ? fund.computedSharePriceAnnualisedReturns
    : fund.computedNavAnnualisedReturns;

  const value = computedReturns?.find(
    computedReturn => computedReturn.timePeriod === timePeriod,
  )?.percentage;

  const output = format ? format(value) : roundOrEmpty(value, 1, '%');
  return output;
};
