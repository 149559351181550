import { GetResearchResponse, ResearchApi } from '@aminsights/contract';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import useUserAccess from '@/hooks/useUserAccess';
import queryClient from '@/queryClient';
import { openApiConfig } from '@/utils';
import { USER_PERMISSIONS } from '@aminsights/shared';

type UseResearchWidgetResponse = GetResearchResponse;
type UseResearchWidgetError = unknown; // If API had error types place here
type UseResearchWidgetOptions = Omit<
  UseQueryOptions<UseResearchWidgetResponse, UseResearchWidgetError>,
  'queryKey' | 'queryFn' | 'initialData'
>;

const RESEARCH_WIDGET_QUERY_KEY = 'research_widget';
export const invalidateResearchWidget = () => {
  queryClient.invalidateQueries({ queryKey: [RESEARCH_WIDGET_QUERY_KEY] });
};
const useGetResearchWidgetQuery = (
  allowRequest = true,
  options?: UseResearchWidgetOptions,
) => {
  const { hasPermissions } = useUserAccess();
  const hasResearchViewPermissions = hasPermissions([
    USER_PERMISSIONS.research.read,
  ]);
  const { state: featureSwitch } = useFeatureSwitchContext();
  return useQuery<UseResearchWidgetResponse, UseResearchWidgetError>(
    [RESEARCH_WIDGET_QUERY_KEY],
    async () => {
      if (
        !hasResearchViewPermissions ||
        !featureSwitch.isResearchDashboardEnabled
      ) {
        return { overdue: [], upcoming: [] };
      }
      const api = new ResearchApi(openApiConfig());
      const result = await api.getResearchWidget();
      return result.data;
    },
    { ...options, enabled: allowRequest },
  );
};

export default useGetResearchWidgetQuery;
