import { FileAttachment } from '@aminsights/contract';
import { InboxOutlined } from '@ant-design/icons';
import { Button, Modal, Upload, UploadFile, UploadProps } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import { ReactComponent as ArrowBackIcon } from '@/assets/svg/icons/icon-arrow-back.svg';
import { APP_ACTIONS } from '@/constants/app';
import { useAppContext } from '@/context/AppContext';

import FilesInfoToast from './FilesInfoToast';
import {
  MAX_FILES_ALLOWED,
  SUPPORTED_FILE_MIME_TYPES,
  convertFileToBase64,
  readFilesFromClipboard,
  uploadFileToStorage,
} from './utils';

const { Dragger } = Upload;

interface FilesUploadModalProps {
  isVisible: boolean;
  storagePath: string;
  currentNoOfFiles?: number;
  toggleModal: () => void;
  onUploadSuccess: (attachments: FileAttachment[]) => void;
}

const FilesUploadModal = ({
  isVisible,
  storagePath,
  toggleModal,
  currentNoOfFiles = 0,
  onUploadSuccess,
}: FilesUploadModalProps) => {
  const [files, setFiles] = useState<UploadFile[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const { dispatch: dispatchApp } = useAppContext();
  const [isMaxFilesToastVisible, setIsMaxFilesToastVisible] = useState(false);
  const [fileWithSizeExceedingLimit, setFileWithSizeExceedingLimit] =
    useState<UploadFile | null>(null);

  const handleUpdateFileMetadata = useCallback(
    async (file: UploadFile): Promise<UploadFile> => {
      if (file.type?.startsWith('image/') && file.originFileObj) {
        const thumbUrl = await convertFileToBase64(file.originFileObj);
        return { ...file, thumbUrl, status: 'done' };
      }
      return { ...file, status: 'done' };
    },
    [],
  );

  const handleClipboardPaste = useCallback(
    async (event: ClipboardEvent) => {
      if (!isVisible) return;
      event.preventDefault();
      if (!event.clipboardData) return;

      const totalNoOfCurrentFiles = currentNoOfFiles + files.length;
      const filesFromClipboard = await readFilesFromClipboard(
        event,
        totalNoOfCurrentFiles,
      );

      if (totalNoOfCurrentFiles + filesFromClipboard.length > MAX_FILES_ALLOWED)
        setIsMaxFilesToastVisible(true);

      const filteredFiles = filesFromClipboard.slice(
        0,
        MAX_FILES_ALLOWED - totalNoOfCurrentFiles,
      );

      // Check if any file exceeds the size limit of 25MB
      const fileWithSizeExceedingLimit = filteredFiles.find(
        file => (file.size || 0) / 1024 / 1024 > 25,
      );
      if (fileWithSizeExceedingLimit)
        setFileWithSizeExceedingLimit(fileWithSizeExceedingLimit);

      // Filter out files that exceed the size limit of 25MB
      const filesToUpload = filteredFiles.filter(
        file => (file.size || 0) / 1024 / 1024 <= 25,
      );

      if (filesToUpload.length > 0) setFiles([...files, ...filesToUpload]);
    },
    [files, isVisible, currentNoOfFiles],
  );

  useEffect(() => {
    document.addEventListener('paste', handleClipboardPaste);
    return () => {
      document.removeEventListener('paste', handleClipboardPaste);
    };
  }, [handleClipboardPaste]);

  const handleCancel = () => {
    if (isUploading) return;
    setFiles([]);
    toggleModal();
  };

  const handleUploadFiles = async () => {
    try {
      setIsUploading(true);

      const uploadPromises = files.map(async file =>
        uploadFileToStorage(file.originFileObj as File, storagePath),
      );
      const uploadedFiles = await Promise.all(uploadPromises);
      dispatchApp({
        type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
        payload: { text: 'Successfully uploaded files' },
      });

      onUploadSuccess(uploadedFiles);
      setIsUploading(false);
      setFiles([]);
      toggleModal();
    } catch (_error) {
      dispatchApp({
        type: APP_ACTIONS.SET_ERROR_MESSAGE,
        payload: 'Error uploading files',
      });
      setIsUploading(false);
    }
  };

  const draggerProps: UploadProps = {
    name: 'file',
    multiple: true,
    accept: SUPPORTED_FILE_MIME_TYPES.join(','),
    listType: 'picture',
    fileList: files,
    // Disable default upload functionality
    beforeUpload: () => false,
    onChange: async info => {
      const fileList = await Promise.all(
        info.fileList.map(handleUpdateFileMetadata),
      );

      // Check if any file exceeds the size limit of 25MB
      const fileWithSizeExceedingLimit = fileList.find(
        file => (file.size || 0) / 1024 / 1024 > 25,
      );
      if (fileWithSizeExceedingLimit)
        setFileWithSizeExceedingLimit(fileWithSizeExceedingLimit);

      // Filter out files that exceed the size limit of 25MB
      const filesToUpload = fileList.filter(
        file => (file.size || 0) / 1024 / 1024 <= 25,
      );

      if (filesToUpload.length + currentNoOfFiles >= MAX_FILES_ALLOWED)
        setIsMaxFilesToastVisible(true);

      setFiles(filesToUpload.slice(0, MAX_FILES_ALLOWED - currentNoOfFiles));
    },
    // Added empty function to prevent default preview behavior
    onPreview: () => {},
  };

  return (
    <Modal
      open={isVisible}
      onCancel={handleCancel}
      className="max-sm:full-page-modal action-modal md:min-w-[600px] lg:min-w-[800px]"
      title={
        <div className="flex items-center gap-4">
          <ArrowBackIcon
            className="w-4 h-4 cursor-pointer"
            onClick={handleCancel}
          />
          <h2 className="text-xl font-bold text-neutral-200">
            Upload Attachments
          </h2>
        </div>
      }
      footer={[
        <Button
          key="cancel"
          size="large"
          type="link"
          disabled={isUploading}
          onClick={handleCancel}
        >
          Cancel
        </Button>,
        <Button
          key="save"
          size="large"
          type="primary"
          disabled={files.length === 0 || isUploading}
          loading={isUploading}
          onClick={handleUploadFiles}
        >
          Upload
        </Button>,
      ]}
      destroyOnClose
    >
      <div
        className={`flex flex-col gap-6 
        [&_.ant-upload-list]:mt-4 [&_.ant-upload-list-item]:h-10 [&_.ant-upload-list-item]:rounded
        [&_.ant-upload-list-item]:border-grey-50 [&_.ant-upload-list-item-thumbnail]:w-6 [&_.ant-upload-list-item-thumbnail]:h-auto
        [&_.ant-upload-list-item-image]:w-6 [&_.ant-upload-list-item-image]:h-auto [&_.ant-upload-list-item-name]:text-sm
        [&_.anticon-file]:w-4 [&_.anticon-file]:h-4 [&_.anticon-file]:align-[-2px] [&_.anticon-file>svg]:w-4 [&_.anticon-file>svg]:h-4 
        [&_.ant-upload-list-item-name]:text-primary [&_.ant-upload-list-item-action]:text-neutral-700
         `}
      >
        <p className="text-sm text-neutral-700">
          You can upload files up to 25MB each.
        </p>
        <Dragger
          {...draggerProps}
          disabled={isUploading}
          className={`[&>.ant-upload]:p-2 [&>.ant-upload]:bg-grey-lighter [&>.ant-upload]:rounded 
            [&>.ant-upload]:border [&>.ant-upload]:border-neutral-300 [&>.ant-upload]:hover:border-primary`}
        >
          <div className="flex flex-col gap-y-4 text-center">
            <div className="flex justify-center">
              <InboxOutlined className="text-4xl leading-none text-primary" />
            </div>
            <div className="flex flex-col gap-y-2">
              <p className="text-sm text-neutral-200 font-bold">
                Drag files here or click to upload
              </p>
              <p className="text-xs text-neutral-700">
                Supported formats: .jpg, .pdf, .xlsx, .pptx etc.
              </p>
            </div>
          </div>
        </Dragger>
      </div>
      <FilesInfoToast
        isVisible={isMaxFilesToastVisible || !!fileWithSizeExceedingLimit}
        onClose={() => {
          setIsMaxFilesToastVisible(false);
          setFileWithSizeExceedingLimit(null);
        }}
        message={
          fileWithSizeExceedingLimit
            ? `${fileWithSizeExceedingLimit.name} is too big. Max size: 25MB.`
            : undefined
        }
      />
    </Modal>
  );
};

export default FilesUploadModal;
