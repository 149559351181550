import { Button } from 'antd';
import { FC } from 'react';

import { ReactComponent as GraphicCommentary } from '@/assets/svg/graphic-commentary.svg';

interface EmptyCommentaryStateProps {
  onAddCommentary: () => void;
  showWriteAction: boolean;
}

const EmptyCommentaryState: FC<EmptyCommentaryStateProps> = ({
  onAddCommentary,
  showWriteAction,
}) => (
  <div className="flex flex-col items-center justify-center w-full gap-y-6 text-center p-5 py-16 md:py-32">
    <GraphicCommentary />
    <div className="flex flex-col gap-y-2">
      <h3 className="text-base font-semibold">No commentary has been added</h3>
      {showWriteAction && (
        <p className="leading-5 text-neutral-200">
          Add your first fund commentary using the button below
        </p>
      )}
    </div>

    {showWriteAction && (
      <Button
        onClick={onAddCommentary}
        className="w-full h-10 md:w-48 landscape:w-48 text-sm font-medium"
        type="primary"
      >
        + Add commentary
      </Button>
    )}
  </div>
);

export default EmptyCommentaryState;
