import CustomToaster from '@/components/Toast';
import { openJiraForm } from '@/utils/jiraForm';

interface FilesInfoToastProps {
  isVisible: boolean;
  onClose: () => void;
  message?: string;
}

const FilesInfoToast: React.FC<FilesInfoToastProps> = ({
  isVisible,
  onClose,
  message,
}) => {
  return (
    <CustomToaster
      isVisible={isVisible}
      type="info"
      message={
        !!message ? (
          message
        ) : (
          <p>
            <span className="underline cursor-pointer" onClick={openJiraForm}>
              Tell us
            </span>{' '}
            if you think we should allow more than 8 attachments
          </p>
        )
      }
      onClose={onClose}
    />
  );
};

export default FilesInfoToast;
