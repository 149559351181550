import { Bucket, Fund, Performance } from '@aminsights/contract';
import {
  LIMIT_FUNDS_FOR_CHARTING,
  MonthCode,
  calculateCalendarYearPerformanceTableBackgroundColor,
  isFund,
  roundOrEmpty,
} from '@aminsights/shared';
import { Checkbox, Skeleton } from 'antd';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { COLOR_FUND_FEATURED } from '@/constants/colors';
import { UseOnCheckedRowReturn } from '@/hooks/useOnCheckedRow';
import { RenderAnnualisedPerformancePcl } from '@/partials/RenderFundValues/RenderAnnualisedPerformancePcl';
import { RenderAnnualisedReturnPct } from '@/partials/RenderFundValues/RenderAnnualisedReturnPct';
import { RenderCalendarYearPerformancePcl } from '@/partials/RenderFundValues/RenderCalendarYearPerformancePcl';
import { RenderCalendarYearReturnPct } from '@/partials/RenderFundValues/RenderCalendarYearReturnPct';
import FundInvestmentTrustColumn from '@/partials/columns/FundInvestmentTrustColumn';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';
import React from 'react';

type WatchlistCalendarPerformanceColumnsProps = {
  rowSelectionState: UseOnCheckedRowReturn<Fund>;
  currentBucket?: Bucket | undefined;
  onSelectAll: (isChecked: boolean) => void;
};

export const WatchlistCalendarPerformanceColumns = ({
  rowSelectionState,
  currentBucket,
  onSelectAll,
}: WatchlistCalendarPerformanceColumnsProps): Array<IDataTableColumns> => {
  const { onCheckedRow, checkedRows, isAllCheckboxChecked } = rowSelectionState;
  const screenWidthMode = getScreenWidthMode();

  const columnDefinitions: Array<IDataTableColumns> = [
    {
      title: (
        <div className="whitespace-normal text-start">
          Fund/Investment Trust
        </div>
      ),
      dataTestIdSuffix: 'Fund/Investment Trust',
      sortKey: 'fundName',
      idSubText: 'fundName',
      backgroundColor: (item: Fund) => {
        const isFeaturedRow = currentBucket?.funds.find(
          f => item._id === f?.isin,
        )?.isFeatured;
        return isFeaturedRow ? COLOR_FUND_FEATURED(isFeaturedRow) : '';
      },
      headerCheckbox: (): React.ReactNode => (
        <div className="mr-2" onClick={e => e.stopPropagation()}>
          <Checkbox
            onClick={(event: React.MouseEvent<HTMLInputElement>) => {
              const isChecked = event.currentTarget.checked;
              onSelectAll(isChecked);
              event.stopPropagation();
            }}
            checked={isAllCheckboxChecked}
          />
        </div>
      ),
      render: (item: Fund | Performance): React.ReactNode => {
        return isFund(item) ? (
          <div className="flex items-center">
            <div className="mr-2 md:mr-7" onClick={e => e.stopPropagation()}>
              <Checkbox
                value={item._id}
                onChange={e => onCheckedRow([e.target.value])}
                onClick={e => e.stopPropagation()}
                checked={checkedRows.includes(item._id)}
                disabled={
                  checkedRows.length >= LIMIT_FUNDS_FOR_CHARTING &&
                  !checkedRows.includes(item._id)
                }
              />
            </div>
            <div>
              <FundInvestmentTrustColumn
                data={{
                  id: item._id,
                  name: item.shareClassDetails.code,
                  type: item.legalStructure,
                  assetClassCode: item.assetClassCode,
                  fundId: item.fundId,
                  legalStructure: item.legalStructure,
                }}
              />
            </div>
          </div>
        ) : (
          // empty ant-avatar-sm is just for to mock avatar
          <div className="flex flex-row">
            <div className="hidden mr-4 ant-avatar-sm sm:block" />
            <div className="font-bold table-cell-value text-darkest">
              {item.name}
            </div>
          </div>
        );
      },
      renderType: 'custom',
      align: 'between',
      width: screenWidthMode[ScreenWidthEnum.MaxMd] ? 176 : 320,
      isColumnFixed: true,
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'YTD',
      idSubText: 'ytd',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: `computedNavAnnualisedReturns.percentage.${MonthCode.YTD}`,
          render: (item: Fund) => {
            return (
              <RenderAnnualisedReturnPct
                fund={item}
                timePeriod={MonthCode.YTD}
              />
            );
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: `annualisedPerformancesPcl.value.${MonthCode.YTD}`,
          render: (item: Fund | Performance): React.ReactNode => {
            if (isFund(item)) {
              return (
                <RenderAnnualisedPerformancePcl
                  fund={item}
                  timePeriod={MonthCode.YTD}
                />
              );
            }
            return roundOrEmpty(item?.totalReturnYtdPct, 0);
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund) => {
            return isFund(item)
              ? calculateCalendarYearPerformanceTableBackgroundColor(
                  item,
                  MonthCode.YTD,
                )
              : '';
          },
        },
      ],
    },
    ...[1, 2, 3, 4, 5].map(yearsAgo => {
      const year = new Date().getFullYear() - yearsAgo;

      // @deprecated do not use for funds
      const keyReturn = `totalReturn${yearsAgo}calendarYearsAgoPct`;
      // @deprecated do not use for funds
      const keyPcl = `totalReturn${yearsAgo}calendarYearsAgoCategoryPcl`;

      return {
        title: year.toString(),
        idSubText: year.toString(),
        renderType: 'custom' as const,
        align: 'center',
        children: [
          {
            title: 'Return',
            sortKey: `calendarYearReturnsPct.percentage.${year}`,
            render: (item: Fund | Performance): React.ReactNode => {
              if (isFund(item)) {
                return <RenderCalendarYearReturnPct fund={item} year={year} />;
              }

              const value = roundOrEmpty(
                item?.[keyReturn as keyof Performance],
                2,
                '%',
              );
              return value;
            },
            renderType: 'number' as const,
            width: 85,
          },
          {
            title: 'Pcl',
            sortKey: `calendarYearPerformancesPcl.value.${year}`,
            render: (item: Fund | Performance) => {
              if (isFund(item)) {
                return (
                  <RenderCalendarYearPerformancePcl fund={item} year={year} />
                );
              }

              return roundOrEmpty(item?.[keyPcl as keyof Performance], 0);
            },
            renderType: 'number' as const,
            width: 85,
            backgroundColor: (item: Fund) => {
              const matchingYear = item.calendarYearPerformancesPcl?.find(
                a => a.year === year,
              )?.year;
              return isFund(item) && matchingYear
                ? calculateCalendarYearPerformanceTableBackgroundColor(
                    item,
                    matchingYear,
                  )
                : '';
            },
          },
        ],
      };
    }),
  ];

  return columnDefinitions;
};
