import { Fund } from '@aminsights/contract';
import {
  RipsReturnType,
  isInvestmentTrust,
  roundOrEmpty,
} from '@aminsights/shared';

interface Props {
  fund: Fund;
  year: number;
}

export const RenderCalendarYearReturnPct = ({ fund, year }: Props) => {
  const { calendarYearReturnsPct } = fund;

  const targetYear = calendarYearReturnsPct?.filter(a => a.year === year);

  const targetReturnType = isInvestmentTrust(fund.legalStructure)
    ? RipsReturnType.SharePrice
    : RipsReturnType.TotalReturn;

  const returnPct = targetYear?.find(
    d => d.returnType === targetReturnType,
  )?.percentage;

  return roundOrEmpty(returnPct, 2, '%');
};
