import { FileAttachment } from '@aminsights/contract';
import {
  FileOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Button, Image, Space } from 'antd';
import { useState } from 'react';

import { downloadFile, downloadFilesAsZip, isFileImage } from '@/utils';

interface AttachmentsListingProps {
  attachments: FileAttachment[];
}

const MAX_ATTACHMENTS_TO_SHOW = 5;

const AttachmentsListing = ({ attachments }: AttachmentsListingProps) => {
  const [selectedAttachment, setSelectedAttachment] =
    useState<FileAttachment | null>(null);
  const [isDownloadingAttachments, setIsDownloadingAttachments] =
    useState(false);
  const [showMoreAttachments, setShowMoreAttachments] = useState(false);

  const handlePreviewImage = (attachment: FileAttachment) => {
    setSelectedAttachment(attachment);
  };
  const handleDownloadFile = (attachment: FileAttachment) => {
    downloadFile(attachment.fileUrl ?? '', attachment.originalFileName);
  };

  const handleDownloadAllAttachments = async () => {
    try {
      setIsDownloadingAttachments(true);
      await downloadFilesAsZip(
        attachments.map(attachment => ({
          fileUrl: attachment.fileUrl ?? '',
          fileName: attachment.originalFileName,
        })),
      );
      setIsDownloadingAttachments(false);
    } catch (error) {
      setIsDownloadingAttachments(false);
    }
  };

  return (
    <div className="flex flex-col">
      {attachments
        .slice(0, !showMoreAttachments ? MAX_ATTACHMENTS_TO_SHOW : undefined)
        .map(attachment => (
          <div
            key={attachment.storageFileKey}
            className="flex gap-2 items-center h-8 py-1"
          >
            <div
              className="w-6 h-6 flex items-center justify-center cursor-pointer"
              onClick={() =>
                !isFileImage(attachment.originalFileName)
                  ? handleDownloadFile(attachment)
                  : undefined
              }
            >
              {isFileImage(attachment.originalFileName) ? (
                <Image
                  width={24}
                  src={attachment.fileUrl}
                  preview={{
                    visible:
                      selectedAttachment?.storageFileKey ===
                      attachment.storageFileKey,
                    onVisibleChange(value) {
                      setSelectedAttachment(value ? attachment : null);
                    },
                    toolbarRender: (
                      _,
                      {
                        transform: { scale },
                        actions: { onZoomOut, onZoomIn },
                      },
                    ) => (
                      <Space
                        size={12}
                        className="flex justify-around items-center w-30 h-14 py-4 px-6 rounded-full bg-[#54557680]"
                      >
                        <ZoomOutOutlined
                          className={`text-lg leading-none align-[0px] ${scale === 1 ? 'text-neutral-50' : 'text-white'}`}
                          disabled={scale === 1}
                          onClick={scale > 1 ? onZoomOut : undefined}
                        />
                        <ZoomInOutlined
                          className={`text-lg leading-none align-[0px] ${scale > 10 ? 'text-neutral-50' : 'text-white'}`}
                          disabled={scale > 10}
                          onClick={scale <= 10 ? onZoomIn : undefined}
                        />
                      </Space>
                    ),
                  }}
                />
              ) : (
                <FileOutlined className="w-4 h-4 leading-none text-neutral-100 cursor-pointer" />
              )}
            </div>
            <p
              className="text-sm text-primary hover:text-primary-400 cursor-pointer"
              onClick={() =>
                isFileImage(attachment.originalFileName)
                  ? handlePreviewImage(attachment)
                  : handleDownloadFile(attachment)
              }
            >
              {attachment.originalFileName}
            </p>
          </div>
        ))}
      {attachments.length > MAX_ATTACHMENTS_TO_SHOW && (
        <div className="flex gap-4 items-center mt-2">
          <p
            className="text-sm text-neutral-700 font-medium cursor-pointer"
            onClick={() => setShowMoreAttachments(prev => !prev)}
          >
            <span className="text-lg">{showMoreAttachments ? '-' : '+'}</span>{' '}
            {showMoreAttachments
              ? 'Show less'
              : `${attachments.length - MAX_ATTACHMENTS_TO_SHOW} more attachments`}
          </p>
          <Button
            type="primary"
            size="large"
            className="px-4"
            loading={isDownloadingAttachments}
            onClick={handleDownloadAllAttachments}
          >
            Download all attachments ({attachments.length})
          </Button>
        </div>
      )}
    </div>
  );
};

export default AttachmentsListing;
