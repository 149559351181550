import { Fund, Performance } from '@aminsights/contract';
import {
  MonthCode,
  calculateCalendarYearPerformanceTableBackgroundColor,
  isFund,
  roundOrEmpty,
} from '@aminsights/shared';
import { Skeleton } from 'antd';
import React from 'react';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import { TableCheckboxHeader } from '@/pages/app/Explore/components/TableCheckbox';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import { RenderAnnualisedPerformancePcl } from '../RenderFundValues/RenderAnnualisedPerformancePcl';
import { RenderAnnualisedReturnPct } from '../RenderFundValues/RenderAnnualisedReturnPct';
import { RenderCalendarYearPerformancePcl } from '../RenderFundValues/RenderCalendarYearPerformancePcl';
import { RenderCalendarYearReturnPct } from '../RenderFundValues/RenderCalendarYearReturnPct';
import FundNameCheckboxWrapper, {
  CheckboxParams,
} from './FundNameCheckboxWrapper';

const CalendarYearPerformanceColumns = (
  checkboxParams: CheckboxParams,
  dataTableRenderedAt: DataTableRenderedAt,
  showPrimaryShareClassIndicator?: boolean,
): IDataTableColumns[] => {
  const screenWidthMode = getScreenWidthMode();

  return [
    {
      headerCheckbox: (): React.ReactNode =>
        dataTableRenderedAt !== DataTableRenderedAt.Watchlist && (
          <div className="">
            <TableCheckboxHeader
              onClick={checkboxParams.onCheckedRow}
              topISINs={checkboxParams.topISINs}
              checked={checkboxParams.isAllCheckboxChecked}
            />
          </div>
        ),
      title: (
        <div className="whitespace-normal text-start">
          Fund/Investment Trust
        </div>
      ),
      dataTestIdSuffix: 'Fund/Investment Trust',
      sortKey: 'shareClassDetails.code',
      render: (item: Fund): React.ReactNode => {
        return (
          <FundNameCheckboxWrapper
            item={item}
            checkboxParams={checkboxParams}
            dataTableRenderedAt={dataTableRenderedAt}
            showPrimaryShareClassIndicator={
              showPrimaryShareClassIndicator ?? false
            }
          />
        );
      },
      renderType: 'custom',
      align: 'between',
      width: screenWidthMode[ScreenWidthEnum.MaxMd] ? 176 : 320,
      isColumnFixed: true,
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'YTD',
      idSubText: 'ytd',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: `computedNavAnnualisedReturns.percentage.${MonthCode.YTD}`,
          render: (item: Fund) => {
            return (
              <RenderAnnualisedReturnPct
                fund={item}
                timePeriod={MonthCode.YTD}
              />
            );
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: `annualisedPerformancesPcl.value.${MonthCode.YTD}`,
          render: (item: Fund | Performance): React.ReactNode => {
            if (isFund(item)) {
              return (
                <RenderAnnualisedPerformancePcl
                  fund={item}
                  timePeriod={MonthCode.YTD}
                />
              );
            } else {
              return roundOrEmpty(item?.totalReturnYtdPct, 0);
            }
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund) => {
            return isFund(item)
              ? calculateCalendarYearPerformanceTableBackgroundColor(
                  item,
                  MonthCode.YTD,
                )
              : '';
          },
        },
      ],
    },
    ...[1, 2, 3, 4, 5].map(yearsAgo => {
      const year = new Date().getFullYear() - yearsAgo;

      // @deprecated do not use for funds
      const keyReturn = `totalReturn${yearsAgo}calendarYearsAgoPct`;
      // @deprecated do not use for funds
      const keyPcl = `totalReturn${yearsAgo}calendarYearsAgoCategoryPcl`;

      return {
        title: year.toString(),
        idSubText: year.toString(),
        renderType: 'custom' as const,
        align: 'center',
        children: [
          {
            title: 'Return',
            sortKey: `calendarYearReturnsPct.percentage.${year}`,
            render: (item: Fund | Performance): React.ReactNode => {
              if (isFund(item)) {
                return <RenderCalendarYearReturnPct fund={item} year={year} />;
              }

              const value = roundOrEmpty(
                item?.[keyReturn as keyof Performance],
                2,
                '%',
              );
              return value;
            },
            renderType: 'number' as const,
            width: 85,
          },
          {
            title: 'Pcl',
            sortKey: `calendarYearPerformancesPcl.value.${year}`,
            render: (item: Fund | Performance) => {
              if (isFund(item)) {
                return (
                  <RenderCalendarYearPerformancePcl fund={item} year={year} />
                );
              }

              return roundOrEmpty(item?.[keyPcl as keyof Performance], 0);
            },
            renderType: 'number' as const,
            width: 85,
            backgroundColor: (item: Fund) => {
              const matchingYear = item.calendarYearPerformancesPcl?.find(
                a => a.year === year,
              )?.year;
              return isFund(item) && matchingYear
                ? calculateCalendarYearPerformanceTableBackgroundColor(
                    item,
                    matchingYear,
                  )
                : '';
            },
          },
        ],
      };
    }),
  ];
};

export default CalendarYearPerformanceColumns;
