import React, { PropsWithChildren, useMemo } from 'react';

import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import useGetResearchWidgetQuery from '@/hooks/query-hooks/research-hooks/useResearchWidget';
import useNotificationSettings from '@/hooks/query-hooks/settings-hooks/useNotificationSettings';
import useUserAccess from '@/hooks/useUserAccess';
import ResearchTable from '@/partials/ResearchTable';
import { ResearchTableType } from '@/types/research';
import { APP_ROUTE_RESEARCH, USER_PERMISSIONS } from '@aminsights/shared';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import ResearchLegend from '../../components/ResearchLegend';
import { RESEARCH_WIDGET_ROWS_LIMIT } from './const';

const ResearchWidget: React.FC<PropsWithChildren> = () => {
  const { state: featureSwitch } = useFeatureSwitchContext();
  const { hasPermissions } = useUserAccess();
  const hasResearchViewPermissions = hasPermissions([
    USER_PERMISSIONS.research.read,
  ]);
  const { data, isLoading } = useGetResearchWidgetQuery();
  const { data: notificationSettings, isLoading: isSettingsLoading } =
    useNotificationSettings();

  const upcomingData = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.upcoming.slice(0, RESEARCH_WIDGET_ROWS_LIMIT);
  }, [data]);

  const overdueData = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.overdue.slice(0, RESEARCH_WIDGET_ROWS_LIMIT);
  }, [data]);

  return hasResearchViewPermissions &&
    featureSwitch.isResearchDashboardEnabled ? (
    <div className="pb-6 mb-6 md:pt-0 bg-white lg:rounded-lg">
      <div className="p-4">
        <div className="w-full flex justify-between py-2">
          <h5 className="text-sm font-bold text-darkest">Research</h5>
          <div className="flex justify-between gap-4">
            <ResearchLegend className="gap-4" />
            <Button
              size="small"
              type="link"
              className="p-0 text-primary font-semibold"
              onClick={() => window.scrollTo({ top: 0 })}
            >
              <Link to={APP_ROUTE_RESEARCH}>View All</Link>
            </Button>
          </div>
        </div>
        <div className="flex justify-between flex-col md:flex-row gap-4">
          {/* Take only the lastest 4 meetings(top of desc timeline) considering month difference from today */}
          <ResearchTable
            tableType={ResearchTableType.Overdue}
            isLoading={isLoading || isSettingsLoading}
            data={overdueData}
            notificationSettings={notificationSettings?.notesAndMeetings}
          />
          {/* Take only the earliest 4 items since now. For notes, consider sum(month + updatedAt)*/}
          <ResearchTable
            tableType={ResearchTableType.Upcoming}
            isLoading={isLoading || isSettingsLoading}
            data={upcomingData}
            notificationSettings={notificationSettings?.notesAndMeetings}
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ResearchWidget;
