import { Fund } from '@aminsights/contract';
import { EMPTY_DATA_POINT, MonthCode, roundOrEmpty } from '@aminsights/shared';

type RequiredFundProps = {
  legalStructure?: string;
  annualisedPerformancesPcl?: Fund['annualisedPerformancesPcl'];
};

interface Props<T extends RequiredFundProps> {
  fund?: T;
  timePeriod: MonthCode;
  format?: (value: number | undefined) => string;
}

export const RenderAnnualisedPerformancePcl = <T extends RequiredFundProps>({
  fund,
  timePeriod,
  format,
}: Props<T>) => {
  if (!fund || !fund.legalStructure) {
    console.error('No fund or legal structure found to get computed return');
    return EMPTY_DATA_POINT;
  }

  const value = fund.annualisedPerformancesPcl?.find(
    pcl => pcl.timePeriod === timePeriod,
  )?.value;

  const output = format ? format(value) : roundOrEmpty(value, 0);
  return output;
};
