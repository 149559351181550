export enum USER_ASSETS_STORAGE_PATH {
  NOTES = 'notes',
  COMMENTS = 'comments',
}

// Types of supported files to upload
// Documents: .pdf, .doc, .docx, .txt, .md
// Spreadsheets: .xls, .xlsx, .csv, .tsv
// Presentations: .ppt, .pptx, .key
// Images: .jpg, .jpeg, .png, .svg
export enum SUPPORTED_FILE_EXTENSIONS {
  PDF = 'pdf',
  DOC = 'doc',
  DOCX = 'docx',
  TXT = 'txt',
  MD = 'md',
  XLS = 'xls',
  XLSX = 'xlsx',
  CSV = 'csv',
  TSV = 'tsv',
  PPT = 'ppt',
  PPTX = 'pptx',
  KEY = 'key',
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
  SVG = 'svg',
}
