import {
  Bucket,
  BucketRequestBody,
  ResourceUserRoleEnum,
} from '@aminsights/contract';
import { APP_ROUTE_WATCHLIST } from '@aminsights/shared';
import { Dropdown, Input } from 'antd';
import cx from 'classnames';
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as SharedIcon } from '@/assets/svg/icons/icon-shared.svg';
import { ReactComponent as MeatballMenu } from '@/assets/svg/meatball-menu.svg';
import { APP_ACTIONS } from '@/constants';
import { setErrorMessage, useAppContext } from '@/context/AppContext';
import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import { useBenchmarkOptions } from '@/hooks/query-hooks/benchmark-hooks/useManageBenchmarks';
import {
  useDeleteBucket,
  useRemoveSharedBucket,
  useShareBucket,
  useUpdateBucket,
} from '@/hooks/query-hooks/bucket-hooks/useManageBuckets';
import { useSectors } from '@/hooks/query-hooks/sector-hooks/useSectors';
import { WATCHLIST } from '@/hooks/query-hooks/watchlist-hooks/query-keys';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import BenchmarksDropdown from '@/partials/BenchmarksDropdown';
import ShareModalBucket from '@/partials/ManageBuckets/ShareBucketModal';
import ConfirmationModalDanger from '@/partials/Modal/ConfirmationModalDanger';
import NestedDrawer from '@/partials/NestedDrawer';
import SectorsDropdown from '@/partials/SectorsDropdown';
import queryClient from '@/queryClient';
import { ScreenWidthEnum } from '@/utils/getScreenWidthMode';
import getScreenWidthMode from '@/utils/getScreenWidthMode';

import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import style from '../style.module.less';
import RevokeBucketAccessModal from './RevokeBucketAccessModal';
import BucketSkeleton from './Skeleton';
import RenderFunds from './renderFunds';

const BucketDefault: React.FC<
  PropsWithChildren<{
    id: string;
    name: string;
    index: string;
    funds: Bucket['funds'];
    order: number;
    sector?: string;
    active?: boolean;
  }>
> = props => {
  const history = useHistory();
  const { id: bucketId, name, index, funds, order, active } = props;
  const { dispatch: dispatchApp } = useAppContext();
  const renameBucketRef = useRef<HTMLDivElement>(null);
  const [isBucketNameInputShown, setIsBucketNameInputShown] = useState(false);
  const [isIndexDropdownShown, setIsIndexDropdownShown] = useState(false);
  const [isSectorDropdownShown, setIsSectorDropdownShown] = useState(false);
  const [isDeleteBucketModalOpen, setIsDeleteBucketModalOpen] = useState(false);
  const [isRemoveSharedBucketModalOpen, setIsRemoveSharedBucketModalOpen] =
    useState(false);
  const [renameBucketName, setRenameBucketName] = useState(name);
  const [isBucketNameExists, setIsBucketNameExists] = useState(false);
  const [benchmarkIndex, setBenchmarkIndex] = useState('');
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const { state: authState } = useContext(AxiosAuthContext);
  const currentUser = authState.decodedToken;
  const {
    data: benchmarkOptionsResponse,
    isLoading: isBenchmarkOptionsLoading,
  } = useBenchmarkOptions();
  const { data: sectors, isLoading: isSectorsLoading } = useSectors();
  const featureSwitch = useFeatureSwitchContext();
  const { isBucketFoldersEnabled } = featureSwitch.state;

  const currentWatchlist = useCurrentWatchlist();
  const { isLoading } = currentWatchlist;
  const updateBucketMutation = useUpdateBucket();
  const deleteMutation = useDeleteBucket();
  const removeBucketMutation = useRemoveSharedBucket();
  const shareBucketMutation = useShareBucket();

  const bucketUsers =
    currentWatchlist.data?.buckets?.find(b => b.id === bucketId)?.users || [];
  const currentBucketUser = bucketUsers.find(bu => bu.id === currentUser.sub);
  const isBucketShared = bucketUsers.length > 1;
  const canManageBucket =
    ResourceUserRoleEnum.Editor === currentBucketUser?.role;

  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];

  const [isNestedDrawerVisible, setIsNestedDrawerVisible] = useState(false);

  const getMenuItemsData = () => {
    const allMenuItems = [
      {
        label: 'Rename',
        key: 'rename',
        onClick: () => {
          setIsBucketNameInputShown(true);
          if (isMobile) {
            setIsNestedDrawerVisible(false);
          }
        },
        'data-test-id': 'bucketRenameButton',
      },
      ...(!isBucketFoldersEnabled
        ? [
            {
              label: 'Reorder bucket',
              key: 'reorderBucket',
              onClick: () => {
                history.push(`/${APP_ROUTE_WATCHLIST}/change-bucket-order`);
                if (isMobile) {
                  setIsNestedDrawerVisible(false);
                }
              },
              'data-test-id': 'bucketChangeBucketOrderButton',
            },
          ]
        : []),
      {
        label: 'Share',
        key: 'shareBucket-menu',
        onClick: () => setIsShareModalOpen(true),
      },
      ...(isBucketFoldersEnabled
        ? [
            {
              label: 'Add to folder',
              key: 'addBucketToFolder',
              children: [],
            },
          ]
        : []),
      {
        label: 'Delete',
        key: 'delete',
        'data-test-id': 'deleteBucketButton',
        danger: true,
        onClick: !isBucketShared
          ? () => {
              if (isMobile) setIsNestedDrawerVisible(false);
              setIsDeleteBucketModalOpen(true);
            }
          : undefined,
        children: isBucketShared
          ? [
              {
                label: 'Delete for me',
                key: 'deleteBucketForMe',
                danger: true,
                onClick: () => {
                  if (isMobile) setIsNestedDrawerVisible(false);
                  setIsRemoveSharedBucketModalOpen(true);
                },
              },
              ...(canManageBucket
                ? [
                    {
                      label: 'Delete for everyone',
                      key: 'deleteBucketForEveryone',
                      danger: true,
                      onClick: () => {
                        if (isMobile) setIsNestedDrawerVisible(false);
                        setIsDeleteBucketModalOpen(true);
                      },
                    },
                  ]
                : []),
            ]
          : undefined,
      },
    ];

    if (!canManageBucket)
      return allMenuItems.filter(i => i.key !== 'reorderBucket');

    return allMenuItems;
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  const bucketMenuItems = useMemo(
    () => getMenuItemsData(),
    [
      setIsBucketNameInputShown,
      setIsIndexDropdownShown,
      canManageBucket,
      setIsShareModalOpen,
      setIsDeleteBucketModalOpen,
      bucketUsers,
    ],
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setRenameBucketName(e.target.value);
  };

  const updateBucketName = (bucketName = '') => {
    updateBucketMutation.mutate(
      {
        bucketId,
        bucket: {
          name: bucketName,
        },
      },
      {
        onSuccess: () => {
          setRenameBucketName(bucketName);
          dispatchApp({
            type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
            payload: { text: 'Successfully renamed bucket' },
          });
        },
        onError: error => {
          setErrorMessage({
            dispatch: dispatchApp,
            error,
            errorAdditionalText: 'Error renaming bucket',
          });
          setRenameBucketName('');
        },
      },
    );
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isBucketNameExists) {
      if (e.key === 'Enter') {
        if (renameBucketName !== name) updateBucketName(renameBucketName);
        setIsBucketNameInputShown(false);
      }
    }
  };
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    const isBucketNameExists = currentWatchlist.data?.buckets
      ? currentWatchlist.data.buckets.some(
          b => b.name === renameBucketName && b.name !== name,
        )
      : false;

    setIsBucketNameExists(isBucketNameExists);
  }, [renameBucketName, isBucketNameInputShown]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (!isBucketNameExists) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [renameBucketRef, isBucketNameExists, renameBucketName]);

  const handleClickOutside = () => {
    if (renameBucketRef.current?.id && renameBucketName !== name) {
      updateBucketName(renameBucketRef.current?.id);
    }
    setIsBucketNameInputShown(false);
  };

  const onDeleteClick = () => {
    return deleteMutation.mutateAsync(bucketId);
  };

  const sectorName = useMemo(() => {
    return sectors?.find(s => s.sectorId === props.sector)?.name;
  }, [sectors, props.sector]);

  if (isLoading || isSectorsLoading) {
    return <BucketSkeleton loading={true} />;
  }

  const updateBenchmark = (selectedBenchmark?: {
    selectedValue: string;
    indexName?: string;
  }) => {
    updateBucketMutation.mutateAsync(
      selectedBenchmark
        ? {
            bucketId,
            /**
             * TODO: There is a type issue,
             * BucketUpdateRequestBody does not have index but is required
             * to work. buckets.controller.ts the updateBucket function actually
             * expects BucketRequestBody NOT BucketUpdateRequestBody.
             */
            bucket: {
              indexSecId: selectedBenchmark.selectedValue,
              index: selectedBenchmark.indexName,
            } as BucketRequestBody,
          }
        : {
            bucketId,
            bucket: {
              index: '',
              indexSecId: '',
            } as BucketRequestBody,
          },
      {
        onSuccess: () => {
          setBenchmarkIndex(selectedBenchmark?.indexName ?? '');
          if (selectedBenchmark) {
            setRenameBucketName('');
          }
          dispatchApp({
            type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
            payload: { text: 'Successfully updated index' },
          });
        },
        onError: error => {
          setErrorMessage({
            dispatch: dispatchApp,
            error,
            errorAdditionalText: 'Index not updated',
          });
          setRenameBucketName('');
        },
        onSettled: () => {
          queryClient.invalidateQueries([WATCHLIST]);
          setIsIndexDropdownShown(false);
        },
      },
    );
  };

  const clearBenchmark = () => {
    updateBenchmark();
  };

  const updateSector = (selectedValue?: string) => {
    updateBucketMutation.mutateAsync(
      selectedValue
        ? {
            bucketId,
            bucket: {
              sector: selectedValue,
            } as BucketRequestBody,
          }
        : {
            bucketId,
            bucket: {
              sector: '',
            } as BucketRequestBody,
          },
      {
        onSuccess: () => {
          dispatchApp({
            type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
            payload: { text: 'Successfully updated sector' },
          });
        },
        onError: error => {
          setErrorMessage({
            dispatch: dispatchApp,
            error,
            errorAdditionalText: 'Error updating sector',
          });
        },
        onSettled: () => {
          queryClient.invalidateQueries([WATCHLIST]);
          setIsSectorDropdownShown(false);
        },
      },
    );
  };

  const clearSector = () => {
    updateSector();
  };

  return (
    <>
      <span className="text-xs font-medium text-neutral">
        Bucket {order + 1}
      </span>
      <div
        className={cx(
          'bg-[#F7F7F7] w-full flex flex-col p-4 rounded select-none mt-2',
          active && 'bg-info-100',
          'hover:shadow-md transition-shadow duration-300',
        )}
      >
        <div>
          <div className="flex justify-between items-center cursor-pointer">
            <div
              className="text-base font-semibold my-0 w-full text-[#181920]"
              role="button"
              onClick={() => {
                if (canManageBucket) setIsBucketNameInputShown(true);
              }}
            >
              <div
                className={cx(
                  { hidden: isBucketNameInputShown },
                  'text-darkest',
                )}
              >
                {renameBucketName || name}{' '}
                <span className="font-normal text-base text-neutral">
                  ({funds?.length || 0}){' '}
                </span>
              </div>
              {isBucketNameInputShown && (
                <div ref={renameBucketRef} id={renameBucketName}>
                  <Input
                    autoFocus
                    type="text"
                    defaultValue={name}
                    value={renameBucketName || name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(e)
                    }
                    onMouseDown={e => e.stopPropagation()}
                    onKeyDown={onKeyDown}
                    data-test-id="bucketRenameInput"
                  />
                  {isBucketNameExists && (
                    <div className="font-medium text-xs text-danger pt-1 pl-1">
                      Bucket name already exists
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className={style['bucket__head-actions']}>
              {isBucketShared && (
                <SharedIcon
                  height={30}
                  width={30}
                  style={{ padding: 'unset' }}
                  onClick={
                    canManageBucket
                      ? () => setIsShareModalOpen(true)
                      : undefined
                  }
                  className={cx(canManageBucket && 'cursor-pointer')}
                />
              )}
              <Dropdown
                menu={{ items: isMobile ? [] : bucketMenuItems }}
                placement="bottomRight"
                trigger={['click']}
                overlayClassName={`
                  w-48 [&_.ant-dropdown-menu-submenu-title>.ant-dropdown-menu-title-content]:!text-destructive-500
                  [&_.ant-dropdown-menu-submenu-open]:bg-destructive-50
                  [&_.ant-dropdown-menu-submenu-expand-icon]:text-neutral-700`}
              >
                <div data-test-id="bucketMenuDropdown">
                  <MeatballMenu
                    width={20}
                    height={20}
                    className="!text-neutral-700"
                    onClick={() => {
                      isMobile && setIsNestedDrawerVisible(true);
                    }}
                  />
                </div>
              </Dropdown>
              {isMobile && (
                <NestedDrawer
                  menuItems={bucketMenuItems}
                  visible={isNestedDrawerVisible}
                  onClose={() => setIsNestedDrawerVisible(false)}
                  title="Select"
                  key={bucketMenuItems.length}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col gap-2 mt-2 mb-3">
            {!isIndexDropdownShown && index.length ? (
              <p
                role="button"
                onClick={() => {
                  if (canManageBucket) setIsIndexDropdownShown(true);
                }}
                className="text-sm font-medium cursor-pointer leading-5 !text-neutral-700"
              >
                {benchmarkIndex || index}
              </p>
            ) : (
              <BenchmarksDropdown
                className={style['index-dropdown']}
                placeholder={index || 'Select index'}
                value={index}
                benchmarkOptions={benchmarkOptionsResponse || []}
                onSelect={selectedValue => {
                  const indexName = benchmarkOptionsResponse?.find(
                    b => b.id === selectedValue,
                  )?.name;
                  updateBenchmark({ selectedValue, indexName });
                }}
                onClear={clearBenchmark}
                isOptionsLoading={isBenchmarkOptionsLoading}
                hideCustomOptions
                disabled={!canManageBucket}
              />
            )}
            {!isSectorDropdownShown && sectorName && (
              <p
                role="button"
                onClick={() => {
                  if (canManageBucket) setIsSectorDropdownShown(true);
                }}
                className="text-sm font-medium cursor-pointer leading-5 !text-neutral-700"
              >
                {sectorName}
              </p>
            )}
            {(isSectorDropdownShown || !sectorName) && (
              <SectorsDropdown
                value={props.sector || ''}
                options={(sectors || []).map(({ name, sectorId }) => ({
                  label: name,
                  value: sectorId,
                }))}
                isOptionsLoading={isSectorsLoading}
                isValuePlaceholder={true}
                onSelect={updateSector}
                onClear={clearSector}
                disabled={!canManageBucket}
              />
            )}
          </div>

          <div className={style.bucket__body}>
            <RenderFunds
              funds={funds || []}
              bucketId={bucketId || ''}
              showActions={canManageBucket}
            />
          </div>
        </div>
      </div>
      <ConfirmationModalDanger
        modalInfo={{
          title: isBucketShared ? 'Delete for everyone' : 'Delete bucket?',
          description: isBucketShared
            ? 'This bucket will be permanently deleted.'
            : 'This action cannot be undone.',
          primaryActionLabel: 'Delete',
          succesMessage: 'Successfully deleted a bucket',
          errorMessage: 'Error deleting a bucket',
        }}
        isVisible={isDeleteBucketModalOpen}
        loading={deleteMutation.isLoading}
        toggleModal={() => setIsDeleteBucketModalOpen(prev => !prev)}
        onConfirm={onDeleteClick}
      />
      <RevokeBucketAccessModal
        isVisible={isRemoveSharedBucketModalOpen}
        toggleModal={() => setIsRemoveSharedBucketModalOpen(prev => !prev)}
        bucketUsers={bucketUsers}
        onRevokeBucketAccess={async (usersWithEditAccess: string[]) => {
          if (usersWithEditAccess.length) {
            const bucketUsersToShare = bucketUsers.filter(
              bu => bu.id !== currentUser.sub,
            );
            await shareBucketMutation.mutateAsync({
              bucketId,
              users: bucketUsersToShare.map(u => ({
                id: u.id,
                role: usersWithEditAccess.includes(u.id)
                  ? ResourceUserRoleEnum.Editor
                  : u.role,
              })),
            });
          }

          await removeBucketMutation.mutateAsync(bucketId);
        }}
      />
      <ShareModalBucket
        visible={isShareModalOpen}
        title={name}
        bucketId={bucketId}
        onClose={() => setIsShareModalOpen(false)}
      />
    </>
  );
};

export default BucketDefault;
