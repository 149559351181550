import {
  TREATMENT_AI_SEARCH,
  TREATMENT_APP_LIMITED_ACCESS,
  TREATMENT_INSIGHTS,
  TREATMENT_MPS,
  TREATMENT_NAME_BUCKET_FOLDERS,
  TREATMENT_NAME_CLARITY,
  TREATMENT_NAME_FUND_DETAILS_ACTIONS,
  TREATMENT_NAME_WEEKLY_UPDATE,
  TREATMENT_RESEARCH_DASHBOARD,
  TREATMENT_ROLLING_RETURNS,
  TREATMENT_SECTOR_IN_CHARTING,
  TREATMENT_UPLOAD_FILES,
  TreatmentValues,
} from '@aminsights/shared';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer,
} from 'react';

import { getConfigValue } from '@/constants';
import { useScript } from '@/utils/useScript';

export type FeatureSwitchState = {
  isInitialised: boolean;
  isClarityEnabled: boolean;
  isFundDetailsActionsTreatmentEnabled: boolean;
  isWeeklyUpdateTreatementEnabled: boolean;
  isAiSearchEnabled?: boolean;
  isAppLimitedAccessEnabled?: boolean;
  isMPSEnabled?: boolean;
  isSectorInChartingEnabled?: boolean;
  isResearchDashboardEnabled?: boolean;
  isInsightsEnabled?: boolean;
  isRollingReturnsEnabled?: boolean;
  isBucketFoldersEnabled?: boolean;
  isUploadFilesEnabled?: boolean;
};

const initialState: FeatureSwitchState = {
  isClarityEnabled: false,
  isInitialised: false,
  isFundDetailsActionsTreatmentEnabled: false,
  isWeeklyUpdateTreatementEnabled: false,
  isAiSearchEnabled: false,
  isAppLimitedAccessEnabled: false,
  isMPSEnabled: false,
  isSectorInChartingEnabled: false,
  isResearchDashboardEnabled: false,
  isInsightsEnabled: false,
  isRollingReturnsEnabled: false,
  isBucketFoldersEnabled: false,
  isUploadFilesEnabled: false,
};

export enum FEATURE_SWITCH_ACTIONS {
  SET_SPLITS = 'feature-switch:set-splits',
}

type FeatureSwitchDispatchActions = {
  type: FEATURE_SWITCH_ACTIONS;
  payload: FeatureSwitchState;
};

type FeatureSwitchContextProps = {
  state: FeatureSwitchState;
  dispatch: React.Dispatch<FeatureSwitchDispatchActions>;
};

const FeatureSwitchContext = createContext<FeatureSwitchContextProps>({
  state: initialState,
  dispatch: () => ({}),
});

const reducer = (
  state: FeatureSwitchState,
  action: FeatureSwitchDispatchActions,
): FeatureSwitchState => {
  switch (action.type) {
    case FEATURE_SWITCH_ACTIONS.SET_SPLITS: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
        isInitialised: true,
      };
    }
    default:
      return state;
  }
};

const CLARITY_TIMEOUT_MS = 5000;

const waitForClarity = () =>
  new Promise<void>((resolve, reject) => {
    if (window.clarity) {
      resolve();
      return;
    }

    let attempts = 0;
    const maxAttempts = 50;
    const interval = 100;

    const timer = setInterval(() => {
      attempts++;

      if (window.clarity) {
        clearInterval(timer);
        resolve();
        return;
      }

      if (attempts >= maxAttempts) {
        clearInterval(timer);
        reject(console.error('Clarity failed to load'));
      }
    }, interval);

    //error handling
    setTimeout(() => {
      clearInterval(timer);
      reject(console.error('Clarity failed to load'));
    }, CLARITY_TIMEOUT_MS);
  });

const FeatureSwitchProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { treatments, isReady } = useSplitTreatments({
    names: [
      TREATMENT_NAME_CLARITY,
      TREATMENT_NAME_FUND_DETAILS_ACTIONS,
      TREATMENT_NAME_WEEKLY_UPDATE,
      TREATMENT_APP_LIMITED_ACCESS,
      TREATMENT_AI_SEARCH,
      TREATMENT_MPS,
      TREATMENT_SECTOR_IN_CHARTING,
      TREATMENT_RESEARCH_DASHBOARD,
      TREATMENT_INSIGHTS,
      TREATMENT_ROLLING_RETURNS,
      TREATMENT_NAME_BUCKET_FOLDERS,
      TREATMENT_UPLOAD_FILES,
    ],
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (isReady && !state.isInitialised) {
      const clarityTreatment = treatments[TREATMENT_NAME_CLARITY];
      const fundDetailsActionsTreatment =
        treatments[TREATMENT_NAME_FUND_DETAILS_ACTIONS];
      const weeklyUpdateTreatment = treatments[TREATMENT_NAME_WEEKLY_UPDATE];
      const aiSearchTreatment = treatments[TREATMENT_AI_SEARCH];
      const appLimitedAccessTreatment =
        treatments[TREATMENT_APP_LIMITED_ACCESS];
      const mpsTreatment = treatments[TREATMENT_MPS];
      const sectorInChartingTreatment =
        treatments[TREATMENT_SECTOR_IN_CHARTING];
      const researchDashboardTreatment =
        treatments[TREATMENT_RESEARCH_DASHBOARD];
      const insightsTreatment = treatments[TREATMENT_INSIGHTS];
      const rollingReturnsTreatment = treatments[TREATMENT_ROLLING_RETURNS];
      const bucketFoldersTreatment = treatments[TREATMENT_NAME_BUCKET_FOLDERS];
      const uploadFilesTreatment = treatments[TREATMENT_UPLOAD_FILES];

      dispatch({
        type: FEATURE_SWITCH_ACTIONS.SET_SPLITS,
        payload: {
          isInitialised: true,
          isClarityEnabled: clarityTreatment?.treatment === TreatmentValues.On,
          isFundDetailsActionsTreatmentEnabled:
            fundDetailsActionsTreatment?.treatment === TreatmentValues.On,
          isWeeklyUpdateTreatementEnabled:
            weeklyUpdateTreatment?.treatment === TreatmentValues.On,
          isAiSearchEnabled:
            aiSearchTreatment?.treatment === TreatmentValues.On,
          isAppLimitedAccessEnabled:
            appLimitedAccessTreatment?.treatment === TreatmentValues.On,
          isMPSEnabled: mpsTreatment?.treatment === TreatmentValues.On,
          isSectorInChartingEnabled:
            sectorInChartingTreatment?.treatment === TreatmentValues.On,
          isResearchDashboardEnabled:
            researchDashboardTreatment?.treatment === TreatmentValues.On,
          isInsightsEnabled:
            insightsTreatment?.treatment === TreatmentValues.On,
          isRollingReturnsEnabled:
            rollingReturnsTreatment?.treatment === TreatmentValues.On,
          isBucketFoldersEnabled:
            bucketFoldersTreatment?.treatment === TreatmentValues.On,
          isUploadFilesEnabled:
            uploadFilesTreatment?.treatment === TreatmentValues.On,
        },
      });
    }
  }, [isReady, treatments]);

  useEffect(() => {
    if (state.isClarityEnabled) {
      const hasClarityConsent = localStorage.getItem('clarity_consent');

      if (!hasClarityConsent) {
        waitForClarity()
          .then(() => {
            window.clarity?.('consent', 'grant');
            localStorage.setItem('clarity_consent', 'granted');
          })
          .catch(error => {
            console.warn('Failed to initialize Clarity:', error);
          });
      }
    }
  }, [state.isClarityEnabled]);

  useScript(
    `/clarity.${getConfigValue('REACT_APP_NODE_ENV')}.js`,
    'ms_clarity',
    !state.isClarityEnabled,
  );

  return (
    <FeatureSwitchContext.Provider value={{ state, dispatch }}>
      {state.isInitialised ? <>{children}</> : null}
    </FeatureSwitchContext.Provider>
  );
};

export { FeatureSwitchProvider };

export const useFeatureSwitchContext = (): FeatureSwitchContextProps => {
  return useContext(FeatureSwitchContext);
};

export default FeatureSwitchContext;
