import { AssetAllocationList, WatchlistApi } from '@aminsights/contract';
import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from '@tanstack/react-query';

import { SIZE_PER_PAGE } from '@/constants';
import { openApiConfig } from '@/utils';

import queryClient from '@/queryClient';
import { PageParams } from '../pageParams';
import { RESEARCH } from './query-keys';

export const invalidateGetAllResearchQuery = () => {
  queryClient.invalidateQueries({ queryKey: [RESEARCH] });
};
/**
 * We need to use infiniteQuery to not break the parent hook. This should be refactored someday
 * if the component rendering the table is refactored.
 */
const useGetAllResearchQuery = (
  params?: PageParams,
  options?: Omit<
    UseInfiniteQueryOptions<AssetAllocationList, unknown>,
    'queryKey' | 'queryFn'
  >,
) => {
  const { sortKey, sortDirection } = params ?? {};
  const watchlistApi = new WatchlistApi(openApiConfig());

  return useInfiniteQuery<AssetAllocationList, unknown>(
    [RESEARCH, sortKey, sortDirection],
    async ({ pageParam = 1 }) => {
      const response = await watchlistApi.getWatchlistAssetAllocation({
        page: pageParam,
        size: SIZE_PER_PAGE,
        sortBy: sortKey,
        sortDirection: sortDirection,
      });
      return response.data;
    },
    {
      getNextPageParam: () => undefined, // This tells react-query that there are no more pages
      ...options,
    },
  );
};

export default useGetAllResearchQuery;
