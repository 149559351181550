import {
  MyResearchType,
  ResearchApi,
  ResearchTabResponse,
} from '@aminsights/contract';
import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from '@tanstack/react-query';

import { SIZE_PER_PAGE } from '@/constants';
import { openApiConfig } from '@/utils';

import queryClient from '@/queryClient';
import { PageParams } from '../pageParams';
import { RESEARCH, RESEARCH_MY_RESEARCH_DRAFT } from './query-keys';

export const invalidateGetDraftResearchQuery = () => {
  queryClient.invalidateQueries({
    queryKey: [RESEARCH, RESEARCH_MY_RESEARCH_DRAFT],
  });
};
/**
 * We need to use infiniteQuery to not break the parent hook. This should be refactored someday
 * if the component rendering the table is refactored.
 */
const useGetDraftResearchQuery = (
  params?: PageParams,
  options?: Omit<
    UseInfiniteQueryOptions<ResearchTabResponse, unknown>,
    'queryKey' | 'queryFn'
  >,
) => {
  const { sortKey, sortDirection } = params ?? {};
  const researchApi = new ResearchApi(openApiConfig());

  return useInfiniteQuery<ResearchTabResponse, unknown>(
    [RESEARCH, RESEARCH_MY_RESEARCH_DRAFT, sortKey, sortDirection],
    async ({ pageParam = 1 }) => {
      const response = await researchApi.getMyResearchTable(
        MyResearchType.Drafts,
        {
          page: pageParam,
          size: SIZE_PER_PAGE,
        },
      );
      return response.data;
    },
    {
      getNextPageParam: () => undefined, // This tells react-query that there are no more pages
      ...options,
    },
  );
};

export default useGetDraftResearchQuery;
