import { Fund, Performance } from '@aminsights/contract';
import {
  MonthCode,
  RipsReturnType,
  findReturnByMonthCode,
  isInvestmentTrust,
} from '@aminsights/shared';

import { findPercentileByMonthCode } from '@aminsights/shared';
import dayjs from 'dayjs';
import {
  IAnnualisedPerformanceData,
  ICalendarYearPerformanceData,
} from './types';

export const computedAnnualisedRowMapper = (
  key: string,
  fund: Fund,
): IAnnualisedPerformanceData => {
  return {
    key,
    name: fund?.fundName,
    ytdReturn: findReturnByMonthCode(
      fund,
      MonthCode.YTD,
      RipsReturnType.TotalReturn,
    ),
    ytdPercentile: findPercentileByMonthCode(fund, MonthCode.YTD),

    threeMonthsReturn: findReturnByMonthCode(
      fund,
      MonthCode.M3,
      RipsReturnType.TotalReturn,
    ),
    threeMonthsPercentile: findPercentileByMonthCode(fund, MonthCode.M3),

    sixMonthsReturn: findReturnByMonthCode(
      fund,
      MonthCode.M6,
      RipsReturnType.TotalReturn,
    ),
    sixMonthsPercentile: findPercentileByMonthCode(fund, MonthCode.M6),

    oneYearReturn: findReturnByMonthCode(
      fund,
      MonthCode.M12,
      RipsReturnType.TotalReturn,
    ),
    oneYearPercentile: findPercentileByMonthCode(fund, MonthCode.M12),

    threeYearsReturn: findReturnByMonthCode(
      fund,
      MonthCode.M36,
      RipsReturnType.TotalReturn,
    ),
    threeYearsPercentile: findPercentileByMonthCode(fund, MonthCode.M36),

    fiveYearsReturn: findReturnByMonthCode(
      fund,
      MonthCode.M60,
      RipsReturnType.TotalReturn,
    ),
    fiveYearsPercentile: findPercentileByMonthCode(fund, MonthCode.M60),

    ytdSP: findReturnByMonthCode(
      fund,
      MonthCode.YTD,
      RipsReturnType.SharePrice,
    ),
    threeMonthsSP: findReturnByMonthCode(
      fund,
      MonthCode.M3,
      RipsReturnType.SharePrice,
    ),
    sixMonthsSP: findReturnByMonthCode(
      fund,
      MonthCode.M6,
      RipsReturnType.SharePrice,
    ),
    fiveYearsSP: findReturnByMonthCode(
      fund,
      MonthCode.M60,
      RipsReturnType.SharePrice,
    ),
  };
};

/**
 * Maps a Fund into ICalendarYearPerformanceData
 *
 * As of writing this comment it is used in 2 components:
 *
 * In compare tool:
 *   CompareCalendarYearPerformance.tsx,
 *
 * In fund detail page:
 *   CalendarPerformanceTable.tsx
 */
export const calendarYearPerformanceMapper = (
  key: string,
  fund: Fund,
): ICalendarYearPerformanceData => {
  const year = dayjs().year();

  const totalReturn1calendarYearsAgoCategoryPcl =
    fund.calendarYearPerformancesPcl?.find(
      c => c.year === year - 1 && c.returnType === RipsReturnType.TotalReturn,
    )?.value;
  const totalReturn2calendarYearsAgoCategoryPcl =
    fund.calendarYearPerformancesPcl?.find(
      c => c.year === year - 2 && c.returnType === RipsReturnType.TotalReturn,
    )?.value;
  const totalReturn3calendarYearsAgoCategoryPcl =
    fund.calendarYearPerformancesPcl?.find(
      c => c.year === year - 3 && c.returnType === RipsReturnType.TotalReturn,
    )?.value;
  const totalReturn4calendarYearsAgoCategoryPcl =
    fund.calendarYearPerformancesPcl?.find(
      c => c.year === year - 4 && c.returnType === RipsReturnType.TotalReturn,
    )?.value;
  const totalReturn5calendarYearsAgoCategoryPcl =
    fund.calendarYearPerformancesPcl?.find(
      c => c.year === year - 5 && c.returnType === RipsReturnType.TotalReturn,
    )?.value;

  const sharePriceReturnYtdPct = fund.computedSharePriceAnnualisedReturns?.find(
    pcl => pcl.timePeriod === MonthCode.YTD,
  )?.percentage;
  const sharePriceReturn1calendarYearsAgoPct =
    fund.calendarYearPerformancesPcl?.find(
      c => c.year === year - 1 && c.returnType === RipsReturnType.SharePrice,
    )?.value;
  const sharePriceReturn2calendarYearsAgoPct =
    fund.calendarYearPerformancesPcl?.find(
      c => c.year === year - 2 && c.returnType === RipsReturnType.SharePrice,
    )?.value;
  const sharePriceReturn3calendarYearsAgoPct =
    fund.calendarYearPerformancesPcl?.find(
      c => c.year === year - 3 && c.returnType === RipsReturnType.SharePrice,
    )?.value;
  const sharePriceReturn4calendarYearsAgoPct =
    fund.calendarYearPerformancesPcl?.find(
      c => c.year === year - 4 && c.returnType === RipsReturnType.SharePrice,
    )?.value;
  const sharePriceReturn5calendarYearsAgoPct =
    fund.calendarYearPerformancesPcl?.find(
      c => c.year === year - 5 && c.returnType === RipsReturnType.SharePrice,
    )?.value;

  const mapped = {
    key,
    name: fund.fundName,
    totalReturnYtdPct: fund.computedNavAnnualisedReturns?.find(
      pcl => pcl.timePeriod === MonthCode.YTD,
    )?.percentage,
    totalReturnYtdMorningstarCategoryPcl:
      fund?.totalReturnYtdMorningstarCategoryPcl,
    totalReturn1calendarYearsAgoPct: fund.calendarYearReturnsPct?.find(
      c => c.year === year - 1,
    )?.percentage,
    totalReturn2calendarYearsAgoPct: fund.calendarYearReturnsPct?.find(
      c => c.year === year - 2,
    )?.percentage,
    totalReturn3calendarYearsAgoPct: fund.calendarYearReturnsPct?.find(
      c => c.year === year - 3,
    )?.percentage,
    totalReturn4calendarYearsAgoPct: fund.calendarYearReturnsPct?.find(
      c => c.year === year - 4,
    )?.percentage,
    totalReturn5calendarYearsAgoPct: fund.calendarYearReturnsPct?.find(
      c => c.year === year - 5,
    )?.percentage,

    /**
     * Holy crap this mapper needs to go - used to be mapped in backend
     */
    totalReturn1calendarYearsAgoCategoryPcl: !isInvestmentTrust(
      fund.legalStructure,
    )
      ? totalReturn1calendarYearsAgoCategoryPcl
      : sharePriceReturn1calendarYearsAgoPct,
    totalReturn2calendarYearsAgoCategoryPcl: !isInvestmentTrust(
      fund.legalStructure,
    )
      ? totalReturn2calendarYearsAgoCategoryPcl
      : sharePriceReturn2calendarYearsAgoPct,
    totalReturn3calendarYearsAgoCategoryPcl: !isInvestmentTrust(
      fund.legalStructure,
    )
      ? totalReturn3calendarYearsAgoCategoryPcl
      : sharePriceReturn3calendarYearsAgoPct,
    totalReturn4calendarYearsAgoCategoryPcl: !isInvestmentTrust(
      fund.legalStructure,
    )
      ? totalReturn4calendarYearsAgoCategoryPcl
      : sharePriceReturn4calendarYearsAgoPct,
    totalReturn5calendarYearsAgoCategoryPcl: !isInvestmentTrust(
      fund.legalStructure,
    )
      ? totalReturn5calendarYearsAgoCategoryPcl
      : sharePriceReturn5calendarYearsAgoPct,

    sharePriceReturnYtdPct,
    sharePriceReturn1calendarYearsAgoPct,
    sharePriceReturn2calendarYearsAgoPct,
    sharePriceReturn3calendarYearsAgoPct,
    sharePriceReturn4calendarYearsAgoPct,
    sharePriceReturn5calendarYearsAgoPct,
  };

  return mapped;
};

export const benchmarkAnnualisedMapper = (
  key: string,
  benchmark: Performance,
): IAnnualisedPerformanceData => {
  return {
    key,
    name: benchmark?.name,
    ytdReturn: benchmark?.totalReturnYtdPct,
    threeMonthsReturn: benchmark?.totalReturn3MonthPct,
    sixMonthsReturn: benchmark?.totalReturn6MonthPct,
    oneYearReturn: benchmark?.totalReturn1YearPct,
    threeYearsReturn: benchmark?.totalReturn3YearPct,
    fiveYearsReturn: benchmark?.totalReturn5YearPct,
  };
};

export const benchmarkCalendarYearMapper = (
  key: string,
  benchmark: Performance,
): ICalendarYearPerformanceData => {
  return {
    key,
    name: benchmark.name,
    totalReturnYtdPct: benchmark.totalReturnYtdPct,
    totalReturn1calendarYearsAgoPct: benchmark.totalReturn1calendarYearsAgoPct,
    totalReturn2calendarYearsAgoPct: benchmark.totalReturn2calendarYearsAgoPct,
    totalReturn3calendarYearsAgoPct: benchmark.totalReturn3calendarYearsAgoPct,
    totalReturn4calendarYearsAgoPct: benchmark.totalReturn4calendarYearsAgoPct,
    totalReturn5calendarYearsAgoPct: benchmark.totalReturn5calendarYearsAgoPct,
  };
};
