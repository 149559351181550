import { Comment } from '@aminsights/contract';
import { DISPLAY_DATE_FORMAT, USER_PERMISSIONS } from '@aminsights/shared';
import { Button, Dropdown } from 'antd';
import cx from 'classnames';
import dayjs from 'dayjs';
import { FC, useEffect, useMemo, useState } from 'react';

import { ReactComponent as PinIcon } from '@/assets/svg/icons/icon-pin.svg';
import { ReactComponent as MeatballMenu } from '@/assets/svg/meatball-menu.svg';
import {
  useDeleteComment,
  usePinComment,
  useUnpinComment,
} from '@/hooks/query-hooks/internal-hooks/useManageCommentary';
import useOrganizationUsers from '@/hooks/query-hooks/organization/useOrganizationUsers';
import useUserAccess from '@/hooks/useUserAccess';
import AttachmentsListing from '@/partials/Files/AttachmentsListing';
import ConfirmationModalDanger from '@/partials/Modal/ConfirmationModalDanger';
import NestedDrawer from '@/partials/NestedDrawer';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

interface CommentaryListItemProps {
  comment: Comment;
  highlight?: boolean;
  onEditComment: (comment: Comment) => void;
  searchTerm?: string;
}

const CommentaryListItem: FC<CommentaryListItemProps> = ({
  comment,
  highlight,
  onEditComment,
  searchTerm = '',
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isRecentComment, setIsRecentComment] = useState(false);
  const [isActionMenuVisible, setIsActionMenuVisible] = useState(false);
  const [isNestedDrawerVisible, setIsNestedDrawerVisible] = useState(false);
  const { data: users } = useOrganizationUsers();

  const { currentUser, hasPermissions } = useUserAccess();
  const deleteComment = useDeleteComment();
  const pinComment = usePinComment();
  const unpinComment = useUnpinComment();

  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    const isRecent = dayjs(comment._createdAt).isAfter(
      new Date().getTime() - 1000 * 10,
    );

    let timeout: ReturnType<typeof setTimeout>;
    if (isRecent) {
      setIsRecentComment(true);
      timeout = setTimeout(() => {
        setIsRecentComment(false);
      }, 1000 * 10);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, []);

  const hasWritePermissions = hasPermissions([USER_PERMISSIONS.comments.write]);
  const hasAdminPermissions = hasPermissions([USER_PERMISSIONS.comments.admin]);
  const isCommentOwner = currentUser.sub === comment.createdBy;

  const showEditAndDeleteAction =
    (hasWritePermissions && isCommentOwner) || hasAdminPermissions;
  const isCommentPinned = !!comment.pinnedBy;

  const createdByUser = useMemo(() => {
    return users?.find(user => user.user_id === comment.createdBy)?.name;
  }, [users, comment.createdBy]);

  const pinnedByUser = useMemo(() => {
    return users?.find(user => user.user_id === comment.pinnedBy)?.name;
  }, [users, comment.pinnedBy]);

  const lastUpdatedByUser = useMemo(() => {
    return users?.find(user => user.user_id === comment.updatedBy)?.name;
  }, [users, comment.updatedBy]);

  const userActionsList = [
    ...(showEditAndDeleteAction
      ? [
          {
            key: 'edit',
            label: 'Edit',
            onClick: () => {
              onEditComment(comment);
            },
          },
        ]
      : []),
    {
      key: 'pin',
      label: isCommentPinned ? 'Unpin comment' : 'Pin comment',
      onClick: () => {
        if (isCommentPinned) {
          unpinComment.mutateAsync({ commentId: comment._id });
        } else {
          pinComment.mutateAsync({ commentId: comment._id });
        }
      },
    },
    ...(showEditAndDeleteAction
      ? [
          {
            key: 'delete',
            danger: true,
            label: 'Delete',
            onClick: () => {
              setIsDeleteModalOpen(true);
            },
          },
        ]
      : []),
  ];

  function highlightSearchTerm(text: string, term: string) {
    if (!term || term.trim() === '') return text;

    const lowerText = text.toLowerCase();
    const lowerTerm = term.toLowerCase();

    const highlightLength = term.length;
    const elements: React.ReactNode[] = [];

    let currentIndex = 0;
    let foundIndex = -1;

    while ((foundIndex = lowerText.indexOf(lowerTerm, currentIndex)) !== -1) {
      elements.push(text.slice(currentIndex, foundIndex));

      elements.push(
        <span key={foundIndex} className="bg-[#FAEAA5] px-0.5 font-medium">
          {text.slice(foundIndex, foundIndex + highlightLength)}
        </span>,
      );

      currentIndex = foundIndex + highlightLength;
    }

    if (currentIndex < text.length) {
      elements.push(text.slice(currentIndex));
    }

    return <>{elements}</>;
  }

  return (
    <>
      <ConfirmationModalDanger
        modalInfo={{
          title: 'Delete Commentary?',
          description: 'This action cannot be undone.',
          primaryActionLabel: 'Delete',
          succesMessage: 'Commentary has been deleted',
          errorMessage: 'Unable to delete Commentary',
        }}
        isVisible={isDeleteModalOpen}
        toggleModal={() => setIsDeleteModalOpen(prev => !prev)}
        onConfirm={async () => {
          await deleteComment.mutateAsync({ commentId: comment._id });
        }}
      />
      <div
        className={cx(
          'flex flex-col gap-1 p-4 rounded group border border-neutral-300',
          { 'border-secondary': isRecentComment || highlight },
          { 'bg-primary-50': isCommentPinned },
        )}
      >
        <div className="flex gap-3 items-center relative">
          <div className="flex-1 flex flex-col gap-x-4 gap-y-2 justify-between md:flex-row md:items-center">
            <div className="flex gap-3 md:items-center">
              <p className="text-sm font-bold text-neutral-200">
                {dayjs(comment._createdAt).format(DISPLAY_DATE_FORMAT)}
              </p>
              {isCommentPinned && (
                <div className="hidden md:flex items-center gap-x-1">
                  <PinIcon className="w-4 h-4" />
                  <p className="text-[10px] leading-4 text-neutral">
                    Pinned by:{' '}
                    <span className="font-semibold">{pinnedByUser}</span>{' '}
                    {comment.pinnedAt &&
                      dayjs(comment.pinnedAt).format(DISPLAY_DATE_FORMAT)}
                  </p>
                </div>
              )}
            </div>
            <p className="hidden md:block text-[10px] leading-4 text-neutral">
              {lastUpdatedByUser ? 'Last updated by:' : 'Created by:'}{' '}
              <span className="font-semibold">
                {lastUpdatedByUser ?? createdByUser}
              </span>{' '}
              {lastUpdatedByUser &&
                dayjs(comment._updatedAt).format(DISPLAY_DATE_FORMAT)}
            </p>
          </div>
          {hasWritePermissions && (
            <Dropdown
              open={isActionMenuVisible && !isMobile}
              menu={{
                className:
                  'min-w-40 rounded border border-grey-50 shadow-dropdown p-2',
                items: isMobile ? [] : userActionsList,
              }}
              className={cx('w-8 h-8 p-2 rounded hover:bg-grey-200', {
                'bg-grey-200': isActionMenuVisible || isNestedDrawerVisible,
              })}
              placement="bottomRight"
              trigger={['click']}
              onOpenChange={visible => setIsActionMenuVisible(visible)}
            >
              <Button
                className="border-0"
                size="large"
                type="text"
                icon={<MeatballMenu fill="#3B3C53" width={16} height={16} />}
                onClick={() => isMobile && setIsNestedDrawerVisible(true)}
              />
            </Dropdown>
          )}
          {isMobile && (
            <NestedDrawer
              menuItems={userActionsList}
              visible={isNestedDrawerVisible}
              onClose={() => setIsNestedDrawerVisible(false)}
              title="Select"
            />
          )}
        </div>
        <div className="flex flex-col md:hidden gap-1 items-end">
          {isCommentPinned && (
            <div className="flex items-center gap-x-1">
              <PinIcon className="w-4 h-4" />
              <p className="text-[10px] leading-4 text-neutral">
                Pinned by: <span className="font-semibold">{pinnedByUser}</span>{' '}
                {comment.pinnedAt &&
                  dayjs(comment.pinnedAt).format(DISPLAY_DATE_FORMAT)}
              </p>
            </div>
          )}
          <p className="text-[10px] leading-4 text-neutral">
            {lastUpdatedByUser ? 'Last updated:' : 'Created by:'}{' '}
            <span className="font-semibold">
              {lastUpdatedByUser ?? createdByUser}
            </span>{' '}
            {lastUpdatedByUser &&
              dayjs(comment._updatedAt).format(DISPLAY_DATE_FORMAT)}
          </p>
        </div>
        <p className="text-sm font-normal text-justify text-neutral-700 w-full whitespace-pre-wrap mt-3">
          {searchTerm
            ? highlightSearchTerm(comment.description || '', searchTerm)
            : comment.description}
        </p>
        <div className="flex flex-col gap-y-2">
          <AttachmentsListing attachments={comment.attachments ?? []} />
        </div>
      </div>
    </>
  );
};

export default CommentaryListItem;
