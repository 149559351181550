import { SectorPerformer } from '@aminsights/contract';
import {
  EMPTY_DATA_POINT,
  LEGAL_STRUCTURE,
  MonthCode,
} from '@aminsights/shared';
import { Skeleton } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { DataTable } from '@/components';
import { IDataTableColumns } from '@/components/Table/DataTable';
import { TABLE_UNIQUE_KEY } from '@/constants/misc';
import { RenderAnnualisedReturnPct } from '@/partials/RenderFundValues/RenderAnnualisedReturnPct';
import { ScreenWidthEnum } from '@/utils/getScreenWidthMode';
import getScreenWidthMode from '@/utils/getScreenWidthMode';

interface PerformanceTableProps {
  title: string;
  data: SectorPerformer[];
  isLoading?: boolean;
}

const PerformanceTable: FC<PerformanceTableProps> = ({
  title,
  data,
  isLoading,
}) => {
  const screenWidthMode = getScreenWidthMode();
  const isMobile = screenWidthMode[ScreenWidthEnum.MaxMd];

  const columns: IDataTableColumns<SectorPerformer>[] = [
    {
      title: 'Investment',
      sorter: {
        compare: (a: SectorPerformer, b: SectorPerformer) =>
          a.shareClassDetails?.code.localeCompare(b.shareClassDetails?.code),
        multiple: 3,
      },
      render: (item: SectorPerformer) => (
        <div>
          <Link
            className="w-[144px] sm:w-[200px] font-bold text-neutral-200 hover:text-primary"
            to={`/fund/${item._id}/details`}
          >
            {item.shareClassDetails?.code ?? EMPTY_DATA_POINT}
          </Link>
          {item.legalStructure === LEGAL_STRUCTURE.MPS && (
            <span className="w-[34px] rounded bg-info-50 text-xs font-normal text-info-600 py-0.5 px-1 mt-0.5">
              MPS
            </span>
          )}
        </div>
      ),
      renderType: 'custom',
      isColumnFixed: true,
      width: isMobile ? 176 : 200,
    },
    {
      title: 'YTD',
      render: (item: SectorPerformer) => {
        return (
          <RenderAnnualisedReturnPct fund={item} timePeriod={MonthCode.YTD} />
        );
      },
      renderType: 'number',
      width: 80,
    },
    {
      title: '1 yr',
      render: (item: SectorPerformer) => {
        return (
          <RenderAnnualisedReturnPct fund={item} timePeriod={MonthCode.M12} />
        );
      },
      renderType: 'number',
      width: 80,
    },
    {
      title: 'Sector',
      render: (item: SectorPerformer) => (
        <span className="min-w-[168px]">{item.iaSector}</span>
      ),
      renderType: 'custom',
    },
  ];

  return (
    <div className="flex-1 md:rounded-lg bg-white max-md:pb-4">
      {isLoading ? (
        <div className="p-4">
          <Skeleton.Button style={{ width: '128px', height: '20px' }} active />
        </div>
      ) : (
        <h3 className="text-sm font-bold text-neutral-200 p-4">{title}</h3>
      )}
      <DataTable
        uniqueKey={TABLE_UNIQUE_KEY}
        columns={columns}
        data={data}
        loading={isLoading}
        loaderSize={5}
      />
    </div>
  );
};

export default PerformanceTable;
