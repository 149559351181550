import { Bucket, Fund, Performance } from '@aminsights/contract';
import {
  LIMIT_FUNDS_FOR_CHARTING,
  MonthCode,
  WATCHLIST_TAB_KEY,
  calculateAnnualisedPerformanceTableBackgroundColor,
  isFund,
  isPerformance,
  roundOrEmpty,
} from '@aminsights/shared';
import { Checkbox, Skeleton } from 'antd';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { UseOnCheckedRowReturn } from '@/hooks/useOnCheckedRow';
import { RenderAnnualisedReturnPct } from '@/partials/RenderFundValues/RenderAnnualisedReturnPct';
import FundInvestmentTrustColumn from '@/partials/columns/FundInvestmentTrustColumn';
import { switchTableRowColor } from '@/utils/generate-color';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import useWatchlistAnnualisedPerformanceInfiniteQuery from '@/hooks/query-hooks/watchlist-hooks/useWatchlistAnnualisedPerformanceInfiniteQuery';
import WatchlistColumnAsOfDate from '@/partials/AsOfDates/WatchlistColumnAsOfDate';
import { getLatesAsofDate } from '@/partials/AsOfDates/findLatestAsOfDate';
import { findPercentileByMonthCode } from '@aminsights/shared';
import React from 'react';
type WatchlistAnnualisedPerformanceColumnsProps = {
  rowSelectionState: UseOnCheckedRowReturn<Fund>;
  currentBucket?: Bucket | undefined;
  onSelectAll: (isChecked: boolean) => void;
};

export const WatchlistAnnualisedPerformanceColumns = ({
  rowSelectionState,
  onSelectAll,
  currentBucket,
}: WatchlistAnnualisedPerformanceColumnsProps): Array<IDataTableColumns> => {
  const { onCheckedRow, checkedRows, isAllCheckboxChecked } = rowSelectionState;
  const screenWidthMode = getScreenWidthMode();

  const { data } = useWatchlistAnnualisedPerformanceInfiniteQuery({
    bucketId: currentBucket?.id,
  });
  const pagesData = data?.pages[0].data;

  const latestAnnualisedPerformanceAsOfDate = pagesData
    ? getLatesAsofDate(pagesData, 'computedNavAnnualisedReturns')
    : null;

  const columnDefinitions: Array<IDataTableColumns> = [
    {
      title: (
        <div className="whitespace-normal text-start">
          Fund/Investment Trust
        </div>
      ),
      dataTestIdSuffix: 'Fund/Investment Trust',
      sortKey: 'fundName',
      idSubText: 'fundName',
      backgroundColor: (item: Fund | Performance) => {
        if (isFund(item)) {
          const isFeaturedRow = !!currentBucket?.funds.find(
            f => item._id === f?.isin,
          )?.isFeatured;
          return switchTableRowColor(isFeaturedRow, false, isFeaturedRow) ?? '';
        } else {
          return switchTableRowColor(false, true) ?? '';
        }
      },
      headerCheckbox: (): React.ReactNode => (
        <div className="mr-2" onClick={e => e.stopPropagation()}>
          <Checkbox
            onClick={(event: React.MouseEvent<HTMLInputElement>) => {
              const isChecked = event.currentTarget.checked;
              onSelectAll(isChecked);
              event.stopPropagation();
            }}
            checked={isAllCheckboxChecked}
          />
        </div>
      ),
      render: (item: Fund | Performance): React.ReactNode => {
        return isFund(item) ? (
          <div className="flex items-center relative w-full">
            <div className="mr-2 md:mr-7" onClick={e => e.stopPropagation()}>
              <Checkbox
                value={item._id}
                onChange={e => onCheckedRow([e.target.value])}
                onClick={e => e.stopPropagation()}
                checked={checkedRows.includes(item._id)}
                disabled={
                  checkedRows.length >= LIMIT_FUNDS_FOR_CHARTING &&
                  !checkedRows.includes(item._id)
                }
              />
            </div>
            <div className="flex items-center gap-2 w-full">
              <FundInvestmentTrustColumn
                data={{
                  id: item._id,
                  name: item.shareClassDetails.code,
                  type: item.legalStructure,
                  assetClassCode: item.assetClassCode,
                  fundId: item.fundId,
                  legalStructure: item.legalStructure,
                }}
              />
              <WatchlistColumnAsOfDate
                type={WATCHLIST_TAB_KEY.ANNUALISED_PERFORMANCE}
                fundAsOfDate={item?.computedNavAnnualisedReturns?.[0]?.asOfDate}
              />
            </div>
          </div>
        ) : (
          // empty ant-avatar-sm is just for to mock avatar
          <div className="flex flex-row">
            <div className="hidden mr-4 ant-avatar-sm sm:block" />
            <div className="font-bold table-cell-value text-darkest">
              {item.name}
            </div>
          </div>
        );
      },
      renderType: 'custom',
      align: 'between',
      width: screenWidthMode[ScreenWidthEnum.MaxMd] ? 176 : 320,
      isColumnFixed: true,
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'YTD',
      idSubText: 'ytd',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: `computedNavAnnualisedReturns.percentage.${MonthCode.YTD}`,
          render: (item: Fund | Performance): React.ReactNode => {
            const monthCodeOfYtd = MonthCode.YTD;
            return isPerformance(item) ? (
              roundOrEmpty(item.totalReturnYtdPct, 1, '%')
            ) : (
              <RenderAnnualisedReturnPct
                fund={item}
                timePeriod={monthCodeOfYtd}
              />
            );
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: `annualisedPerformancesPcl.value.${MonthCode.YTD}`,
          render: (item: Fund | Performance): string => {
            const fundValue = isFund(item)
              ? findPercentileByMonthCode(item, MonthCode.YTD)
              : null;
            null;
            const value = roundOrEmpty(fundValue, 0);
            return value;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund | Performance) => {
            return isFund(item)
              ? calculateAnnualisedPerformanceTableBackgroundColor(
                  item,
                  MonthCode.YTD,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '3 mos',
      idSubText: '3mos',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: `computedNavAnnualisedReturns.percentage.${MonthCode.M3}`,
          render: (item: Fund | Performance) => {
            return isPerformance(item) ? (
              roundOrEmpty(item.totalReturn3MonthPct, 1, '%')
            ) : (
              <RenderAnnualisedReturnPct
                fund={item}
                timePeriod={MonthCode.M3}
              />
            );
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: `annualisedPerformancesPcl.value.${MonthCode.M3}`,
          render: (item: Fund | Performance): string => {
            const fundValue = isFund(item)
              ? findPercentileByMonthCode(item, MonthCode.M3)
              : null;
            const value = roundOrEmpty(fundValue, 0);
            return value;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund | Performance) => {
            return isFund(item)
              ? calculateAnnualisedPerformanceTableBackgroundColor(
                  item,
                  MonthCode.M3,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '6 mos',
      idSubText: '6mos',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: `computedNavAnnualisedReturns.percentage.${MonthCode.M6}`,
          render: (item: Fund | Performance) => {
            return isPerformance(item) ? (
              roundOrEmpty(item.totalReturn6MonthPct, 1, '%')
            ) : (
              <RenderAnnualisedReturnPct
                fund={item}
                timePeriod={MonthCode.M6}
              />
            );
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: `annualisedPerformancesPcl.value.${MonthCode.M6}`,
          render: (item: Fund | Performance): string => {
            const fundValue = isFund(item)
              ? findPercentileByMonthCode(item, MonthCode.M6)
              : null;
            const value = roundOrEmpty(fundValue, 0);
            return value;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund | Performance) => {
            return isFund(item)
              ? calculateAnnualisedPerformanceTableBackgroundColor(
                  item,
                  MonthCode.M6,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '1 yr',
      idSubText: '1yr',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: `computedNavAnnualisedReturns.percentage.${MonthCode.M12}`,
          render: (item: Fund | Performance) => {
            return isPerformance(item) ? (
              roundOrEmpty(item.totalReturn1YearPct, 1, '%')
            ) : (
              <RenderAnnualisedReturnPct
                fund={item}
                timePeriod={MonthCode.M12}
              />
            );
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: `annualisedPerformancesPcl.value.${MonthCode.M12}`,
          render: (item: Fund | Performance): string => {
            const fundValue = isFund(item)
              ? findPercentileByMonthCode(item, MonthCode.M12)
              : null;
            const value = roundOrEmpty(fundValue, 0);
            return value;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund | Performance) => {
            return isFund(item)
              ? calculateAnnualisedPerformanceTableBackgroundColor(
                  item,
                  MonthCode.M12,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '3 yrs',
      idSubText: '3yrs',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: `computedNavAnnualisedReturns.percentage.${MonthCode.M36}`,
          render: (item: Fund | Performance) => {
            return isPerformance(item) ? (
              roundOrEmpty(item.totalReturn3YearPct, 1, '%')
            ) : (
              <RenderAnnualisedReturnPct
                fund={item}
                timePeriod={MonthCode.M36}
              />
            );
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: `annualisedPerformancesPcl.value.${MonthCode.M36}`,
          render: (item: Fund | Performance): string => {
            const fundValue = isFund(item)
              ? findPercentileByMonthCode(item, MonthCode.M36)
              : null;
            const value = roundOrEmpty(fundValue, 0);
            return value;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund | Performance) => {
            return isFund(item)
              ? calculateAnnualisedPerformanceTableBackgroundColor(
                  item,
                  MonthCode.M36,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '5 yrs',
      idSubText: '5yrs',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: `computedNavAnnualisedReturns.percentage.${MonthCode.M60}`,
          render: (item: Fund | Performance) => {
            return isPerformance(item) ? (
              roundOrEmpty(item.totalReturn5YearPct, 1, '%')
            ) : (
              <RenderAnnualisedReturnPct
                fund={item}
                timePeriod={MonthCode.M60}
              />
            );
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: `annualisedPerformancesPcl.value.${MonthCode.M60}`,
          render: (item: Fund | Performance): string => {
            const fundValue = isFund(item)
              ? findPercentileByMonthCode(item, MonthCode.M60)
              : null;
            const value = roundOrEmpty(fundValue, 0);
            return value;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund | Performance) => {
            return isFund(item)
              ? calculateAnnualisedPerformanceTableBackgroundColor(
                  item,
                  MonthCode.M60,
                )
              : '';
          },
        },
      ],
    },
  ];

  return columnDefinitions;
};
