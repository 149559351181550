import JSZip from 'jszip';

const ALLOWED_IMAGE_EXTENSIONS = ['png', 'jpeg', 'jpg', 'svg'];

export const isFileImage = (fileName: string) => {
  const extension = fileName.split('.').pop();
  return ALLOWED_IMAGE_EXTENSIONS.includes(extension || '');
};

export const downloadFile = async (fileUrl: string, fileName: string) => {
  const result = await fetch(fileUrl);
  const blob = await result.blob();
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link?.parentNode?.removeChild(link);
};

export const downloadFilesAsZip = async (
  files: {
    fileUrl: string;
    fileName: string;
  }[],
) => {
  const zip = new JSZip();

  const fetchAndAddToZip = async (
    fileUrl: string,
    filename: string,
  ): Promise<void> => {
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) throw new Error(`Failed to fetch ${filename}`);
      const blob = await response.blob();
      zip.file(filename, blob);
    } catch (_error) {
      console.error('Error fetching file');
    }
  };

  const downloadZip = async (): Promise<void> => {
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(zipBlob);
    link.download = 'attachements.zip';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Fetch all files in parallel and add to zip
  await Promise.all(
    files.map((file, index) => fetchAndAddToZip(file.fileUrl, file.fileName)),
  );

  await downloadZip();
};
