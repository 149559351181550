import {
  FileAttachment,
  NoteCategory,
  NoteRecord,
  NoteStatus,
} from '@aminsights/contract';
import {
  DISPLAY_DATE_FORMAT,
  USER_ASSETS_STORAGE_PATH,
} from '@aminsights/shared';
import { Button, Input, Modal, Upload } from 'antd';
import cx from 'classnames';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { ReactComponent as PlusIcon } from '@/assets/svg/icons/icon-plus.svg';
import useOrganizationUsers from '@/hooks/query-hooks/organization/useOrganizationUsers';
import {
  useCreateNote,
  useUpdateNote,
} from '@/hooks/query-hooks/research-hooks/notes-hooks';

import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import useFundByIsin from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import FilesInfoToast from '@/partials/Files/FilesInfoToast';
import FilesUploadModal from '@/partials/Files/FilesUploadModal';
import { MAX_FILES_ALLOWED } from '@/partials/Files/utils';

import { ModalMode } from '../../constants';

type ModalProps = {
  originalNote?: NoteRecord;
  modalNoteInfo: {
    category: NoteCategory;
    mode?: ModalMode;
  };
  isVisible: boolean;
  className?: string;
  toggleModal: () => void;
  width?: number;
};
const { TextArea } = Input;

const NoteModal: FC<ModalProps> = ({
  originalNote,
  modalNoteInfo,
  isVisible,
  className,
  toggleModal,
  width = 400,
}) => {
  const { id: fundMetadataId } = useParams<{ id: string }>();
  const {
    data: { fund } = { fund: undefined },
  } = useFundByIsin(fundMetadataId);
  const { state: featureSwitchState } = useFeatureSwitchContext();
  const [isAttachmentsModalOpen, setIsAttachmentsModalOpen] = useState(false);
  const [isMaxAttachmentsToastVisible, setIsMaxAttachmentsToastVisible] =
    useState(false);
  const createNote = useCreateNote();
  const updateNote = useUpdateNote();

  const formMethods = useForm<{
    description: string;
    attachments?: FileAttachment[];
  }>({
    defaultValues: { description: '', attachments: [] },
    mode: 'all',
  });

  const { control, reset, watch, setValue, formState } = formMethods;

  const attachments = watch('attachments') ?? [];

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (originalNote?.description && originalNote?.attachments) {
      reset({
        description: originalNote.description,
        attachments: originalNote.attachments ?? [],
      });
    }
  }, [originalNote, reset, isVisible]);

  const { data: usersData } = useOrganizationUsers();

  const handleCloseModal = () => {
    if (formState.isSubmitting) return;

    formMethods.reset({ description: '', attachments: [] });
    toggleModal();
  };

  const getUserNameById = (id: string) => {
    const user = usersData?.find(user => {
      return user.user_id === id;
    });
    return user?.name;
  };

  const handleSaveNote = formMethods.handleSubmit(async data => {
    if (modalNoteInfo.mode === ModalMode.Edit && !originalNote) {
      formMethods.setError('description', {
        message: 'Cannot edit note that does not exist at all.',
      });
      return;
    }

    if (modalNoteInfo.mode === ModalMode.Edit && originalNote) {
      await updateNote.mutateAsync({
        fundMetadataId,
        body: {
          ...originalNote,
          description: data.description,
          attachments: (data.attachments ?? []).map(attachment => ({
            originalFileName: attachment.originalFileName,
            storageFileKey: attachment.storageFileKey,
            fileSize: attachment.fileSize,
          })),
        },
      });
    }
    if (modalNoteInfo.mode === ModalMode.Add && fund?.fundName) {
      await createNote.mutateAsync({
        fundMetadataId,
        body: {
          fundMetadataId,
          fundName: fund.fundName,
          description: data.description,
          attachments: (data.attachments ?? []).map(attachment => ({
            originalFileName: attachment.originalFileName,
            storageFileKey: attachment.storageFileKey,
            fileSize: attachment.fileSize,
          })),
          category: modalNoteInfo.category,
          status: NoteStatus.DraftNew,
        },
      });
    }
    formMethods.reset({ description: '', attachments: [] });
    toggleModal();
  });

  return (
    <>
      <Modal
        centered={true}
        width={width}
        open={isVisible && !isAttachmentsModalOpen}
        onCancel={handleCloseModal}
        className={cx(
          'action-modal',
          'action-modal-confirmation',
          'md:min-w-[600px] lg:min-w-[800px]',
          '[&_.ant-modal-body]:py-6 [&_.ant-modal-header]:pt-10 [&_.ant-modal-header]:pb-0 [&_.ant-modal-close]:mr-4 [&_.ant-modal-footer]:pt-0',
          className,
        )}
        title={
          <div className="flex gap-2 flex-col">
            <div className="text-xl font-bold text-darkest">Note</div>
            {modalNoteInfo.mode === ModalMode.Edit &&
              originalNote?.updatedBy && (
                <p className="text-[10px] not-italic font-normal leading-4">
                  Last Updated:{' '}
                  <span className="font-semibold text-neutral">
                    {getUserNameById(originalNote.updatedBy)}
                  </span>{' '}
                  {dayjs(originalNote._updatedAt).format(DISPLAY_DATE_FORMAT)}
                </p>
              )}
          </div>
        }
        footer={[
          <Button
            size="large"
            type="link"
            key="secondary-save"
            onClick={handleCloseModal}
            data-test-id="modalNoteContentCancelButton"
            disabled={formState.isSubmitting}
          >
            Cancel
          </Button>,
          <Button
            size="large"
            onClick={handleSaveNote}
            key="primary-confirm"
            data-test-id="modalNoteContentAddButton"
            type="primary"
            htmlType="submit"
            disabled={!formState.isValid || !formState.isDirty}
            loading={formState.isSubmitting}
          >
            {modalNoteInfo.mode === ModalMode.Edit ? 'Save' : 'Add'}
          </Button>,
        ]}
      >
        <div className="text-base font-normal leading-6">
          <span className="text-xs not-italic font-medium leading-4 text-darkest">
            {modalNoteInfo.category}
          </span>
          <Controller
            name="description"
            control={control}
            rules={{
              required: 'Description is required',
            }}
            render={({ field }) => (
              <TextArea
                className="!placeholder:text-sm !placeholder:text-neutral-100 !placeholder:font-normal !rounded border !border-[#BAC0D0] !border-solid h-[280px] !pb-0 text-sm !text-neutral-700 px-4 py-2 resize-none hover:!border-solid focus:!border-[#007FFF] focus:!border-2"
                id="note"
                placeholder="Write something..."
                {...field}
              />
            )}
          />
        </div>
        <div>
          {featureSwitchState.isUploadFilesEnabled && (
            <Button
              type="link"
              className="mt-1 p-0 text-sm text-primary hover:text-primary-400 gap-1"
              onClick={() => {
                if (attachments.length >= MAX_FILES_ALLOWED)
                  setIsMaxAttachmentsToastVisible(true);
                else setIsAttachmentsModalOpen(true);
              }}
            >
              <PlusIcon className="icon text-lg" />
              Add attachments
            </Button>
          )}
          {attachments.length > 0 && (
            <div
              className={`flex flex-col mt-10 
                  [&_.ant-upload-list]:mt-4 [&_.ant-upload-list-item]:h-10 [&_.ant-upload-list-item]:rounded 
                  [&_.ant-upload-list-item]:border-grey-50 [&_.ant-upload-list-item-thumbnail]:w-6 [&_.ant-upload-list-item-thumbnail]:h-auto 
                  [&_.ant-upload-list-item-image]:w-6 [&_.ant-upload-list-item-image]:h-auto [&_.ant-upload-list-item-name]:text-sm 
                  [&_.anticon-file]:w-4 [&_.anticon-file]:h-4 [&_.anticon-file]:align-[-2px] [&_.anticon-file>svg]:w-4 [&_.anticon-file>svg]:h-4
                  [&_.ant-upload-list-item-name]:text-primary [&_.ant-upload-list-item-action]:text-neutral-700`}
            >
              <p className="text-sm font-bold text-neutral-200">
                Uploaded files summary
              </p>
              <Upload
                listType="picture"
                fileList={attachments.map(attachment => ({
                  uid: attachment.storageFileKey,
                  name: attachment.originalFileName,
                  size: attachment.fileSize,
                  url: attachment.fileUrl,
                  status: 'done',
                }))}
                onRemove={file => {
                  const newAttachments = attachments.filter(
                    attachment => attachment.storageFileKey !== file.uid,
                  );
                  setValue('attachments', newAttachments, {
                    shouldDirty: true,
                  });
                }}
                // Added empty function to prevent default preview behavior
                onPreview={() => {}}
                disabled={formState.isSubmitting}
              />
            </div>
          )}
        </div>
      </Modal>
      <FilesUploadModal
        isVisible={isAttachmentsModalOpen}
        storagePath={USER_ASSETS_STORAGE_PATH.NOTES}
        toggleModal={() => setIsAttachmentsModalOpen(false)}
        onUploadSuccess={(newAttachments: FileAttachment[]) => {
          setValue('attachments', [...attachments, ...newAttachments], {
            shouldDirty: true,
          });
        }}
      />
      <FilesInfoToast
        isVisible={isMaxAttachmentsToastVisible}
        onClose={() => {
          setIsMaxAttachmentsToastVisible(false);
        }}
      />
    </>
  );
};

export default NoteModal;
