import { Modal } from 'antd';
import cx from 'classnames';
import { FC } from 'react';

import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import { AuthUser } from '@aminsights/contract';
import AnalystListing from './AnalystListing';

interface AnalystFormModalProps {
  analysts: AuthUser[];
  isVisible: boolean;
  showModalOnAllLayouts?: boolean;
  close: () => void;
  onRemoveAnalyst: (id: string, name: string) => void;
}

const AllAnalystsModal: FC<AnalystFormModalProps> = ({
  analysts,
  isVisible,
  close,
  showModalOnAllLayouts = false,
  onRemoveAnalyst,
}) => {
  const screenWidthMode = getScreenWidthMode();
  return (
    <Modal
      open={
        isVisible &&
        (showModalOnAllLayouts || screenWidthMode[ScreenWidthEnum.MaxSm])
      }
      className={cx(
        'action-modal [&_.ant-modal-body]:p-4 [&_.ant-modal-body]:pb-0 [&_.ant-modal-close]:mr-4 [&_.ant-modal-footer]:pt-0 [&_.ant-modal-footer]:!justify-end',
        showModalOnAllLayouts ? 'max-sm:full-page-modal' : 'full-page-modal',
      )}
      footer={[]}
      onCancel={close}
    >
      <div className="flex flex-col justify-around gap-2 self-stretch min-h-48 h-full p-4 pb-6 lg:rounded-lg bg-white">
        <h2 className="text-xl font-bold text-neutral-200 ">Analysts</h2>
        <div className="px-4">
          <AnalystListing
            prohibitSelfRemove
            analysts={analysts}
            onRemoveAnalyst={(id, name) => {
              onRemoveAnalyst(id, name);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AllAnalystsModal;
