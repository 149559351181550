import { Fund } from '@aminsights/contract';
import {
  RipsReturnType,
  isInvestmentTrust,
  roundOrEmpty,
} from '@aminsights/shared';

type RequiredFundProps = {
  legalStructure: string;
  calendarYearPerformancesPcl?: Fund['calendarYearPerformancesPcl'];
  annualisedPerformancesPcl?: Fund['annualisedPerformancesPcl'];
};

interface Props<T extends RequiredFundProps> {
  fund: T;
  year: number;
}

export const RenderCalendarYearPerformancePcl = <T extends RequiredFundProps>({
  fund,
  year,
}: Props<T>) => {
  const { calendarYearPerformancesPcl } = fund;

  const targetYear = calendarYearPerformancesPcl?.filter(a => a.year === year);

  const targetReturnType = isInvestmentTrust(fund.legalStructure)
    ? RipsReturnType.SharePrice
    : RipsReturnType.TotalReturn;

  const returnPct = targetYear?.find(
    d => d.returnType === targetReturnType,
  )?.value;

  return roundOrEmpty(returnPct, 0);
};
