import { Meeting, MeetingBody, MeetingsApi } from '@aminsights/contract';
import { useMutation, useQuery } from '@tanstack/react-query';

import { APP_ACTIONS } from '@/constants';
import { setErrorMessage, useAppContext } from '@/context/AppContext';
import queryClient from '@/queryClient';
import { openApiConfig } from '@/utils';

import { invalidateGetResearchHooks } from './invalidateGetResearchHooks';
import { MEETINGS } from './query-keys';

const meetingsApi = new MeetingsApi(openApiConfig());

const invalidateMeetings = () => {
  queryClient.invalidateQueries({ queryKey: [MEETINGS] });
};
const onSettled = () => {
  invalidateMeetings();
  invalidateGetResearchHooks();
};

export const useGetMeetings = (fundMetadataId: string) => {
  return useQuery<Meeting[] | null, unknown>(
    [MEETINGS, fundMetadataId],
    async () => {
      const result = await meetingsApi.getMeetings(fundMetadataId);
      return result.data;
    },
    { enabled: Boolean(fundMetadataId) },
  );
};

export const useCreateMeeting = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { fundMetadataId: string; body: MeetingBody }) =>
      meetingsApi.createMeeting(input.fundMetadataId, input.body),
    {
      onSettled,
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Meeting has been added' },
        });
      },
      onError: error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: 'Error adding meeting',
        });
      },
    },
  );

  return mutation;
};

export const useUpdateMeeting = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { fundMetadataId: string; meetingId: string; body: MeetingBody }) =>
      meetingsApi.updateMeeting(
        input.fundMetadataId,
        input.meetingId,
        input.body,
      ),
    {
      onSettled,
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Meeting has been updated' },
        });
      },
      onError: error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: 'Error updating meeting',
        });
      },
    },
  );

  return mutation;
};

export const useDeleteMeeting = () => {
  const { dispatch: dispatchApp } = useAppContext();

  return useMutation(
    ({
      fundMetadataId,
      meetingId,
    }: { fundMetadataId: string; meetingId: string }) =>
      meetingsApi.deleteMeeting(fundMetadataId, meetingId),
    {
      onSettled,
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Meeting has been deleted' },
        });
      },
      onError: error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: 'Error deleting meeting',
        });
      },
    },
  );
};
