import { Fund } from '@aminsights/contract';
import {
  EMPTY_DATA_POINT,
  MonthCode,
  findPercentileByMonthCode,
  isFund,
} from '@aminsights/shared';
import { calculateAnnualisedPerformanceTableBackgroundColor } from '@aminsights/shared';
import { Skeleton } from 'antd';
import React from 'react';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import { TableCheckboxHeader } from '@/pages/app/Explore/components/TableCheckbox';
import getScreenWidthMode, {
  ScreenWidthEnum,
} from '@/utils/getScreenWidthMode';

import ExploreColumnAsOfDate from '../AsOfDates/ExploreColumnAsOfDate';
import { RenderAnnualisedReturnPct } from '../RenderFundValues/RenderAnnualisedReturnPct';
import FundNameCheckboxWrapper, {
  CheckboxParams,
} from './FundNameCheckboxWrapper';

const AnnualisedPerformanceColumns = (
  checkboxParams: CheckboxParams,
  dataTableRenderedAt: DataTableRenderedAt,
  showPrimaryShareClassIndicator?: boolean,
): IDataTableColumns[] => {
  const screenWidthMode = getScreenWidthMode();

  return [
    {
      headerCheckbox: (): React.ReactNode =>
        dataTableRenderedAt !== DataTableRenderedAt.Watchlist && (
          <div className="">
            <TableCheckboxHeader
              onClick={checkboxParams.onCheckedRow}
              topISINs={checkboxParams.topISINs}
              checked={checkboxParams.isAllCheckboxChecked}
            />
          </div>
        ),
      title: (
        <div className="whitespace-normal text-start">
          Fund/Investment Trust
        </div>
      ),
      dataTestIdSuffix: 'Fund/Investment Trust',
      sortKey: 'shareClassDetails.code',
      render: (item: Fund): React.ReactNode => {
        return (
          <div className="flex items-center gap-2 w-full">
            <FundNameCheckboxWrapper
              item={item}
              checkboxParams={checkboxParams}
              dataTableRenderedAt={dataTableRenderedAt}
              showPrimaryShareClassIndicator={
                showPrimaryShareClassIndicator ?? false
              }
            />
            <ExploreColumnAsOfDate
              type="annualised"
              fundAsOfDate={item.computedNavAnnualisedReturns?.[0]?.asOfDate}
            />
          </div>
        );
      },
      renderType: 'custom',
      align: 'between',
      width: screenWidthMode[ScreenWidthEnum.MaxMd] ? 176 : 320,
      isColumnFixed: true,
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'YTD',
      idSubText: 'ytd',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: `computedNavAnnualisedReturns.percentage.${MonthCode.YTD}`,
          render: (item: Fund) => {
            return (
              <RenderAnnualisedReturnPct
                fund={item}
                timePeriod={MonthCode.YTD}
              />
            );
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: `annualisedPerformancesPcl.value.${MonthCode.YTD}`,
          render: (item: Fund): string => {
            const value =
              isFund(item) && findPercentileByMonthCode(item, MonthCode.YTD);
            return !!value ? Number(value).toFixed(0) : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund) => {
            return isFund(item)
              ? calculateAnnualisedPerformanceTableBackgroundColor(
                  item,
                  MonthCode.YTD,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '3 mos',
      idSubText: '3mos',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: `computedNavAnnualisedReturns.percentage.${MonthCode.M3}`,
          render: (item: Fund) => {
            return (
              <RenderAnnualisedReturnPct
                fund={item}
                timePeriod={MonthCode.M3}
              />
            );
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: `annualisedPerformancesPcl.value.${MonthCode.M3}`,
          render: (item: Fund): string => {
            const value =
              isFund(item) && findPercentileByMonthCode(item, MonthCode.M3);
            return !!value ? Number(value).toFixed(0) : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund) => {
            return isFund(item)
              ? calculateAnnualisedPerformanceTableBackgroundColor(
                  item,
                  MonthCode.M3,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '6 mos',
      idSubText: '6mos',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: `computedNavAnnualisedReturns.percentage.${MonthCode.M6}`,
          render: (item: Fund) => {
            return (
              <RenderAnnualisedReturnPct
                fund={item}
                timePeriod={MonthCode.M6}
              />
            );
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: `annualisedPerformancesPcl.value.${MonthCode.M6}`,
          render: (item: Fund): string => {
            const value =
              isFund(item) && findPercentileByMonthCode(item, MonthCode.M6);
            return !!value ? Number(value).toFixed(0) : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund) => {
            return isFund(item)
              ? calculateAnnualisedPerformanceTableBackgroundColor(
                  item,
                  MonthCode.M6,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '1 yr',
      idSubText: '1yr',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: `computedNavAnnualisedReturns.percentage.${MonthCode.M12}`,
          render: (item: Fund) => {
            return (
              <RenderAnnualisedReturnPct
                fund={item}
                timePeriod={MonthCode.M12}
              />
            );
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: `annualisedPerformancesPcl.value.${MonthCode.M12}`,
          render: (item: Fund): string => {
            const value =
              isFund(item) && findPercentileByMonthCode(item, MonthCode.M12);
            return !!value ? Number(value).toFixed(0) : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund) => {
            return isFund(item)
              ? calculateAnnualisedPerformanceTableBackgroundColor(
                  item,
                  MonthCode.M12,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '3 yrs',
      idSubText: '3yrs',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: `computedNavAnnualisedReturns.percentage.${MonthCode.M36}`,
          render: (item: Fund) => {
            return (
              <RenderAnnualisedReturnPct
                fund={item}
                timePeriod={MonthCode.M36}
              />
            );
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: `annualisedPerformancesPcl.value.${MonthCode.M36}`,
          render: (item: Fund): string => {
            const value =
              isFund(item) && findPercentileByMonthCode(item, MonthCode.M36);
            return !!value ? Number(value).toFixed(0) : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund) => {
            return isFund(item)
              ? calculateAnnualisedPerformanceTableBackgroundColor(
                  item,
                  MonthCode.M36,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '5 yrs',
      idSubText: '5yrs',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: 'Return',
          sortKey: `computedNavAnnualisedReturns.percentage.${MonthCode.M60}`,
          render: (item: Fund) => {
            return (
              <RenderAnnualisedReturnPct
                fund={item}
                timePeriod={MonthCode.M60}
              />
            );
          },
          renderType: 'number',
          width: 85,
        },
        {
          title: 'Pcl',
          sortKey: `annualisedPerformancesPcl.value.${MonthCode.M60}`,
          render: (item: Fund): string => {
            const value =
              isFund(item) && findPercentileByMonthCode(item, MonthCode.M60);
            return !!value ? Number(value).toFixed(0) : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 85,
          backgroundColor: (item: Fund) => {
            return isFund(item)
              ? calculateAnnualisedPerformanceTableBackgroundColor(
                  item,
                  MonthCode.M60,
                )
              : '';
          },
        },
      ],
    },
    {
      title: '',
      render: () => '',
      renderType: 'text',
    },
  ];
};

export default AnnualisedPerformanceColumns;
