import { ResearchApi, ResearchCardItem } from '@aminsights/contract';
import { useQuery } from '@tanstack/react-query';

import { openApiConfig } from '@/utils';

import queryClient from '@/queryClient';
import { RESEARCH_CARDS } from './query-keys';

export const invalidateResearchCardsQuery = () => {
  queryClient.invalidateQueries({ queryKey: [RESEARCH_CARDS] });
};

const useGetResearchCardsQuery = (allowRequest: boolean) => {
  const researchApi = new ResearchApi(openApiConfig());

  return useQuery<ResearchCardItem[], unknown>(
    [RESEARCH_CARDS],
    async () => {
      const response = await researchApi.getResearchCards();
      return response.data;
    },
    { enabled: allowRequest },
  );
};

export default useGetResearchCardsQuery;
