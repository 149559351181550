import { NoteBody, NoteRecord, NotesApi } from '@aminsights/contract';
import { useMutation, useQuery } from '@tanstack/react-query';

import { APP_ACTIONS } from '@/constants';
import { setErrorMessage, useAppContext } from '@/context/AppContext';
import queryClient from '@/queryClient';
import { openApiConfig } from '@/utils';

import useUserAccess from '@/hooks/useUserAccess';
import { USER_PERMISSIONS } from '@aminsights/shared';
import { invalidateGetResearchHooks } from './invalidateGetResearchHooks';
import { NOTES } from './query-keys';

const noteApi = new NotesApi(openApiConfig());

const invalidateNotes = () => {
  queryClient.invalidateQueries({ queryKey: [NOTES] });
};

const onSettled = () => {
  invalidateNotes();
  invalidateGetResearchHooks();
};

export const useGetNotes = (fundMetadataId: string) => {
  const { hasPermissions } = useUserAccess();
  const hasResearchViewPermissions = hasPermissions([
    USER_PERMISSIONS.research.read,
  ]);
  return useQuery<NoteRecord[]>(
    [NOTES, fundMetadataId],
    async () => {
      const result = await noteApi.getNotes(fundMetadataId);
      return result.data;
    },
    { enabled: Boolean(fundMetadataId) && hasResearchViewPermissions },
  );
};

export const useCreateNote = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { fundMetadataId: string; body: NoteBody }) =>
      noteApi.createNote(input.fundMetadataId, input.body),
    {
      onSettled,
      onSuccess: (data, input) => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: `${input.body.category} has been added` },
        });
      },
      onError: error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: 'Error creating note',
        });
      },
    },
  );

  return mutation;
};

export const usePublishNotes = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: {
      fundMetadataId: string;
      notes: NoteRecord[];
      fundName: string;
    }) => {
      return noteApi.publishNotes(input.fundMetadataId, {
        notes: input.notes,
        fundName: input.fundName,
      });
    },
    {
      onSettled,
      onError: error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: 'Error publishing notes',
        });
      },
    },
  );

  return mutation;
};

export const useUpdateNote = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { fundMetadataId: string; body: NoteBody }) =>
      noteApi.updateNote(input.fundMetadataId, input.body),
    {
      onSettled,
      onSuccess: (_data, input) => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: `${input.body.category} has been updated` },
        });
      },
      onError: error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: 'Error updating note',
        });
      },
    },
  );
  return mutation;
};

export const useDeleteNotes = () => {
  const { dispatch: dispatchApp } = useAppContext();
  const mutation = useMutation(
    (input: { fundMetadataId: string; noteBody: NoteBody[] }) =>
      noteApi.deleteNote(input.fundMetadataId, input.noteBody),
    {
      onSettled,
      onSuccess: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Note/s has been deleted' },
        });
      },
      onError: error => {
        setErrorMessage({
          dispatch: dispatchApp,
          error,
          errorAdditionalText: 'Error deleting note',
        });
      },
    },
  );
  return mutation;
};

export const useGetNotesHistory = (fundMetadataId: string) => {
  return useQuery<NoteRecord[]>(
    [NOTES, fundMetadataId, 'history'],
    async () => {
      const result = await noteApi.getNotesHistory(fundMetadataId);
      return result.data;
    },
  );
};
