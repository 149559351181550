import { NoteRecord, NoteStatus } from '@aminsights/contract';
import { DISPLAY_DATE_FORMAT, USER_PERMISSIONS } from '@aminsights/shared';
import { Dropdown } from 'antd';
import cx from 'classnames';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as MeatballMenu } from '@/assets/svg/meatball-menu.svg';
import useOrganizationUsers from '@/hooks/query-hooks/organization/useOrganizationUsers';
import { useDeleteNotes } from '@/hooks/query-hooks/research-hooks/notes-hooks';
import useUserAccess from '@/hooks/useUserAccess';
import AttachmentsListing from '@/partials/Files/AttachmentsListing';
import ConfirmationModalDanger from '@/partials/Modal/ConfirmationModalDanger';

import { ModalMode } from '../../constants';
import NoteModal from './NoteModal';

const CardNotes: React.FC<{
  note: NoteRecord;
  hideMeatball?: boolean;
}> = props => {
  const { note, hideMeatball } = props;
  const { category, description, status, _updatedAt, updatedBy, createdBy } =
    note;

  const [showModal, setShowModal] = useState<boolean>(false);
  const deleteNote = useDeleteNotes();
  const { id: fundMetadataId } = useParams<{ id: string }>();
  const { data: usersData } = useOrganizationUsers();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const { hasPermissions } = useUserAccess();

  const hasResearchWritePermissions = hasPermissions([
    USER_PERMISSIONS.research.write,
  ]);

  const getUserNameById = (id: string) => {
    const user = usersData?.find(user => {
      return user.user_id === id;
    });
    return user?.name;
  };

  const onClickDeleteNote = async () => {
    await deleteNote.mutateAsync({
      fundMetadataId,
      noteBody: [note],
    });
  };

  const humanizeStatus = (status: NoteStatus) => {
    switch (status) {
      case NoteStatus.DraftNew:
      case NoteStatus.DraftUpdate:
        return 'Draft';

      case NoteStatus.PublishedNew:
      case NoteStatus.PublishedUpdate:
        return 'Published';
    }
  };

  return (
    <>
      <div className="flex flex-col self-stretch rounded border border-neutral-300 p-4 border-solid">
        <div className="flex justify-between sm:items-center w-full mb-2.5 py-0.5">
          <p className="text-sm font-bold">{category}</p>
          <div className="flex flex-col-reverse sm:flex-row items-end sm:items-center gap-x-2">
            <p className="text-neutral-500 text-right text-ellipsis text-[10px] leading-4">
              Last Updated:{' '}
              <span className="font-semibold text-neutral">
                {getUserNameById(updatedBy || createdBy)}
              </span>{' '}
              {dayjs(_updatedAt).format(DISPLAY_DATE_FORMAT)}
            </p>
            <div className="flex items-center gap-2">
              <span
                className={cx(
                  'text-xs text-neutral-400 px-1.5 py-0.5 rounded',
                  status === NoteStatus.DraftNew ||
                    status === NoteStatus.DraftUpdate
                    ? 'bg-[#FCF4C7] !text-[#B87E14]'
                    : 'bg-[#F3FAED] !text-[#007D00]',
                )}
              >
                {humanizeStatus(status)}
              </span>
              <Dropdown
                menu={{
                  className:
                    'min-w-40 rounded border border-grey-50 shadow-dropdown p-2',
                  items: [
                    {
                      key: 'edit',
                      label: 'Edit',
                      onClick: () => {
                        setShowModal(true);
                      },
                    },
                    {
                      key: 'delete',
                      label: 'Delete',
                      danger: true,
                      onClick: () => {
                        setIsDeleteModalVisible(true);
                      },
                    },
                  ],
                }}
                placement="bottomRight"
                autoAdjustOverflow={false}
                align={{ offset: [0, 10] }}
                trigger={['click']}
              >
                <div className="hover:bg-grey-light-50 cursor-pointer py-1.5 rounded">
                  {hasResearchWritePermissions && !hideMeatball && (
                    <MeatballMenu
                      width={16}
                      height={16}
                      className="text-neutral-700 mx-2"
                    />
                  )}
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
        <div>
          <p>{description}</p>
        </div>
        <div className="flex flex-col gap-y-2 mt-2">
          <AttachmentsListing attachments={note.attachments ?? []} />
        </div>
      </div>
      <NoteModal
        originalNote={note}
        modalNoteInfo={{
          category: category,
          mode: ModalMode.Edit,
        }}
        isVisible={showModal}
        toggleModal={() => setShowModal(false)}
      />

      <ConfirmationModalDanger
        isVisible={isDeleteModalVisible}
        modalInfo={{
          title: `Delete ${category}?`,
          succesMessage: 'Note has been deleted',
          description: (
            <span className="text-sm font-normal text-neutral-700">
              This action cannot be undone.
            </span>
          ),
          primaryActionLabel: 'Delete',
        }}
        toggleModal={() => setIsDeleteModalVisible(false)}
        onConfirm={onClickDeleteNote}
      />
    </>
  );
};

export default CardNotes;
