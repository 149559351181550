import { StorageApi } from '@aminsights/contract';
import { UploadFile } from 'antd';

import { openApiConfig } from '@/utils';

// Types of supported files to upload
// Documents: .pdf, .doc, .docx, .txt, .md
// Spreadsheets: .xls, .xlsx, .csv, .tsv
// Presentations: .ppt, .pptx, .key
// Images: .jpg, .jpeg, .png, .svg
export const SUPPORTED_FILE_MIME_TYPES = [
  'image/*',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

// Maximum number of files allowed to upload
export const MAX_FILES_ALLOWED = 8;

const storageApi = new StorageApi(openApiConfig());

export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
};

export const readFilesFromClipboard = async (
  event: ClipboardEvent,
  currentNoOfFiles: number,
) => {
  if (!event.clipboardData) return [];

  const clipboardItems = Array.from(event.clipboardData.items);
  const clipboardFilesPromises = clipboardItems
    .filter(item => item.kind === 'file')
    .map(async item => {
      const file = item.getAsFile();
      if (!file) return null;

      const id = `${Date.now()}-${Math.floor(Math.random() * 10000000)}`;

      const fileWithMetadata: UploadFile<any> = {
        uid: id,
        name: file.name || `unknown.${file.type.split('/')[1]}`,
        size: file.size,
        type: file.type,
        originFileObj: file as any,
        status: 'done',
      };

      if (file.type?.startsWith('image/') && fileWithMetadata.originFileObj) {
        const thumbUrl = await convertFileToBase64(
          fileWithMetadata.originFileObj,
        );
        fileWithMetadata.thumbUrl = thumbUrl;
      }

      return fileWithMetadata;
    });

  const filesFromClipboard = (await Promise.all(clipboardFilesPromises)).filter(
    file => file !== null,
  ) as UploadFile<any>[];

  const supportedFiles = filesFromClipboard.filter(
    file =>
      file.type?.startsWith('image/') ||
      SUPPORTED_FILE_MIME_TYPES.includes(file?.type || ''),
  );

  return supportedFiles;
};

export const uploadFileToStorage = async (file: File, path: string) => {
  const { data } = await storageApi.generateSignedUrls(path, file.name);
  const fileUrl = data.uploadSignedUrl.split('?')[0];
  const storageFileKey = fileUrl.split('/').pop() as string;

  await fetch(data.uploadSignedUrl, {
    body: file,
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
      'Access-Control-Allow-Origin': '*',
    },
  });

  return {
    originalFileName: file.name,
    storageFileKey,
    fileSize: file.size,
    fileUrl: data.downloadSignedUrl,
  };
};
